import React, { createContext, useContext, useState, ReactNode } from 'react'

const RecoverGridFilterContext = createContext<
  | {
      placerFilter: string | undefined
      setPlacerFilter: (v: string | undefined) => void
    }
  | undefined
>(undefined)

export const RecoverGridFilterProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [placerFilter, setPlacerFilter] = useState<string | undefined>(
    undefined
  )

  return (
    <RecoverGridFilterContext.Provider
      value={{ placerFilter, setPlacerFilter }}
    >
      {children}
    </RecoverGridFilterContext.Provider>
  )
}

export const useRecoverGridFilter = () => {
  const context = useContext(RecoverGridFilterContext)
  if (!context)
    throw new Error(
      'useRecoverGridFilter must be used within RecoverGridFilterProvider'
    )
  return context
}
