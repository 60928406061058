import React, { useState } from 'react'
import DisplayAggregates from './DisplayAggregates'
import { AccountDetailsData } from 'src/domain/models/accounts'
import { Box, Grid, Paper, Typography } from 'everchain-uilibrary'
import ChainOfPlacement from './ChainOfPlacement'
import AccountActivity from './AccountActivity'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { ITransactionPostOperations } from 'src/domain/features/post/transaction/transaction'
import AccountDetailMediaFilesTable from './AccountDetailMediaFilesTable'
import { MediaFileDetailsData } from 'src/domain/models/mediaFiles'
import { makeMediaUploadGetData } from 'src/main/factories/feature/get/MediaUpload'
import { makeMediaUploadPostData } from 'src/main/factories/feature/post/MediaUpload'
import AccountDetailMediaFilesForm from './AccountDetailMediaFilesForm'

interface AccountDetailFeatureProps {
  account: AccountDetailsData | undefined
  eCAID: string
  accountOperations: IAccountGetOperations
  isLoading: boolean
  isCreditor: boolean
  isInternal: boolean
  transactionPostOperation: ITransactionPostOperations
  mediaFilesData: MediaFileDetailsData[] | undefined
  isMediaFilesLoading: boolean
  mediaFilesRefetch: () => void
}

const AccountDetailFeature: React.FC<AccountDetailFeatureProps> = ({
  account,
  eCAID,
  accountOperations,
  isLoading,
  isCreditor,
  isInternal,
  transactionPostOperation,
  mediaFilesData,
  isMediaFilesLoading,
  mediaFilesRefetch,
}: AccountDetailFeatureProps) => {
  const businessIds = {
    creditorId: isCreditor || isInternal ? account?.creditorId : null,
    vendorId: !isCreditor || isInternal ? account?.vendorId : null,
  }
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)

  return (
    <Box data-test-id="account-details">
      <Grid container spacing={8}>
        <Grid item xs={12} sm={12}>
          <DisplayAggregates account={account} isLoading={isLoading} />
        </Grid>
      </Grid>
      <Box mt={5}>
        <Grid item xs={12} sm={12}>
          <Paper style={{ padding: 0 }}>
            <Box p={3}>
              <Typography variant="subtitle1" color="black">
                The EverChain
              </Typography>
            </Box>
            <ChainOfPlacement
              eCAID={eCAID}
              accountOperations={accountOperations}
              businessIds={businessIds}
              isCardLoading={isLoading}
            />
          </Paper>
        </Grid>
      </Box>
      <Box mt={5}>
        <Grid item xs={12} sm={12}>
          <Paper style={{ padding: 0 }}>
            <Box p={3}>
              <Typography variant="subtitle1" color="black">
                Account Ledger
              </Typography>
            </Box>
            <AccountActivity
              eCAID={eCAID}
              accountOperations={accountOperations}
              isCardLoading={isLoading}
              transactionPostOperations={transactionPostOperation}
              businessIds={businessIds}
            />
          </Paper>
        </Grid>
      </Box>
      <Box mt={5}>
        <Grid item xs={12} sm={12}>
          <Paper style={{ padding: 0 }}>
            <Box p={3}>
              <Typography variant="subtitle1" color="black">
                Account Media Files
              </Typography>
            </Box>
            {(isCreditor || isInternal) && (
              <AccountDetailMediaFilesForm
                mediaUploadGetOperations={makeMediaUploadGetData()}
                mediaUploadPostOperations={makeMediaUploadPostData()}
                eCAID={eCAID}
                mediaFilesData={mediaFilesData}
                setUploadInProgress={setUploadInProgress}
                mediaFilesRefetch={mediaFilesRefetch}
              />
            )}
            <AccountDetailMediaFilesTable
              mediaFilesData={mediaFilesData}
              loadingTable={isMediaFilesLoading}
              uploadInProgress={uploadInProgress}
              mediaFilesRefetch={mediaFilesRefetch}
            />
          </Paper>
        </Grid>
      </Box>
    </Box>
  )
}

export default AccountDetailFeature
