import {
  Box,
  Content,
  DataTable,
  ExportExcelButton,
  Grid,
  Header,
  Link,
} from 'everchain-uilibrary'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { ACCOUNT_DETAIL, BULK_POST_PLACEMENT } from 'src/presentation/routes'
import { getStandardUri } from 'src/utils/common'
import { PostPlacementTypeEnum } from 'src/utils/constants'

const PostPlacementResult: React.FC<any> = () => {
  const location = useLocation()
  const stateData: any = location.state
  const { data, requestTypeId, allValidAccounts } = stateData

  const notProcessed: any[] = data?.errors || []
  const processed: any[] =
    data?.data?.filter((x: any) => x.hasError === false) || []
  const processmentDataError = data?.errors?.map((account: any) => {
    const found = allValidAccounts?.find(
      (x: any) => x.rowIndex === account.rowIndex
    )
    found['errors'] = account.description
    return found
  })

  const getProcessmentMessage = () => {
    if (notProcessed.length === allValidAccounts.length) {
      return (
        <>
          All accounts failed validation. The list of errors can be seen in the
          grid below.
        </>
      )
    }

    if (notProcessed.length === 0) {
      return (
        <>{allValidAccounts.length} requests have been created successfully.</>
      )
    }

    return (
      <>
        The file was partially processed. The accounts with errors are shown
        below.
        <br />
        <br />
        <span style={{ color: 'green' }}>
          Total accounts processed successfully:{' '}
          <strong>{processed.length}</strong>
        </span>
        <br />
        <br />
        <span style={{ color: 'red' }}>
          Total accounts failed validation:{' '}
          <strong>{notProcessed.length}</strong>
        </span>
      </>
    )
  }

  const GridColumns: any[] = [
    {
      field: 'rowIndex',
      title: 'Row #',
      width: 90,
      show: true,
      editable: false,
    },
    {
      field: 'errors',
      title: 'Error',
      width: 300,
      show: true,
      editable: false,
    },
    {
      field: 'ecaid',
      title: 'ECAID',
      width: 150,
      show: true,
      editable: false,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.ECAID ? (
              <Link
                to={getStandardUri(`${ACCOUNT_DETAIL}/${props.dataItem.ECAID}`)}
              >
                {props.dataItem.ECAID}
              </Link>
            ) : (
              <>{props.dataItem.ECAID}</>
            )}
          </td>
        )
      },
    },
  ]

  const getGridColumns = (): any[] => {
    if (requestTypeId === PostPlacementTypeEnum.Bankrupt) {
      return GridColumns.concat([
        {
          field: 'isStatusUpdate',
          title: 'Is Status Update',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'caseNumber',
          title: 'Case Number',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'chapter',
          title: 'Chapter',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'fileDate',
          title: 'File Date',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'attorneyName',
          title: 'Attorney Name',
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'attorneyPhone',
          title: 'Attorney Phone',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'district',
          title: 'District',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.Deceased) {
      return GridColumns.concat([
        {
          field: 'isStatusUpdate',
          title: 'Is Status Update',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'dateOfDeath',
          title: 'Date Of Death',
          show: true,
          editable: true,
          width: 200,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.Info) {
      return GridColumns.concat([
        {
          field: 'infoRequested',
          title: 'Info Requested',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'infoType',
          title: 'Info Type',
          width: 100,
          show: true,
          editable: true,
        },
      ])
    }

    if (
      requestTypeId === PostPlacementTypeEnum.Legal ||
      requestTypeId === PostPlacementTypeEnum.Fraud ||
      requestTypeId === PostPlacementTypeEnum.Compliance ||
      requestTypeId === PostPlacementTypeEnum.SCRA
    ) {
      return GridColumns.concat([
        {
          field: 'isStatusUpdate',
          title: 'Is Status Update',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'type',
          title: 'Type',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.Retain) {
      return GridColumns.concat([
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
        {
          field: 'numberOfDays',
          title: 'Number Of Days',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (
      requestTypeId === PostPlacementTypeEnum.Other ||
      requestTypeId === PostPlacementTypeEnum.InaccurateData ||
      requestTypeId === PostPlacementTypeEnum.DebtSettlement ||
      requestTypeId === PostPlacementTypeEnum.CCCS
    ) {
      return GridColumns.concat([
        {
          field: 'isStatusUpdate',
          title: 'Is Status Update',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.PaidPrior) {
      return GridColumns.concat([
        {
          field: 'isStatusUpdate',
          title: 'Is Status Update',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'paidPriorDate',
          title: 'Paid Prior Date',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'paidPriorType',
          title: 'Paid Prior Type',
          show: true,
          editable: true,
          width: 150,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.LowBalance) {
      return GridColumns.concat([
        {
          field: 'isStatusUpdate',
          title: 'Is Status Update',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'totalBalanceAtClosure',
          title: 'Total Balance At Closure',
          show: true,
          editable: true,
          width: 200,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.OutOfStat) {
      return GridColumns.concat([
        {
          field: 'isStatusUpdate',
          title: 'Is Status Update',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'outOfStatuteDate',
          title: 'Out Of Statute Date',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'outOfStatuteState',
          title: 'Out Of Statute State',
          show: true,
          editable: true,
          width: 200,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.PifSif) {
      return GridColumns.concat([
        {
          field: 'isStatusUpdate',
          title: 'Is Status Update',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'pifSifType',
          title: 'PIF/SIF Type',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'paymentDate',
          title: 'Payment Date',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'paymentAmount',
          title: 'Payment Amount',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'checkOrReferenceNumber',
          title: 'Check Or Reference Number',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'locationOrStore',
          title: 'Location Or Store',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'consumerAddress',
          title: 'Consumer Address',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'consumerCity',
          title: 'Consumer City',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'consumerState',
          title: 'Consumer State',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'consumerPostalCode',
          title: 'Consumer Postal Code',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'consumerPhoneNumber',
          title: 'Consumer Phone Number',
          show: true,
          editable: true,
          width: 200,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.CreditBureauReporting) {
      return GridColumns.concat([
        {
          field: 'isStatusUpdate',
          title: 'Is Status Update',
          width: 100,
          show: true,
          editable: true,
        },
        {
          field: 'creditBureauType',
          title: 'Credit Bureau Type',
          width: 250,
          show: true,
          editable: true,
        },
        {
          field: 'tradelineAdded',
          title: 'Tradeline Added',
          width: 250,
          show: true,
          editable: true,
        },
        {
          field: 'tradelineRemoved',
          title: 'Tradeline Removed',
          width: 250,
          show: true,
          editable: true,
        },
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    return GridColumns
  }

  return (
    <Box>
      <Content>
        <Header
          title="Bulk Upload Post Placement Result"
          customBackUrl={getStandardUri(BULK_POST_PLACEMENT)}
          style={{ padding: 20 }}
        />
        <Grid
          container
          style={{
            paddingLeft: '20px',
            gap: '24px',
          }}
        >
          <Grid container style={{ gap: 10, color: '#000000', fontSize: 14 }}>
            <Grid item>{getProcessmentMessage()}</Grid>
          </Grid>
          {notProcessed.length > 0 && (
            <Grid
              container
              spacing={2}
              style={{
                width: '92vw',
              }}
            >
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <ExportExcelButton
                  data={processmentDataError}
                  fileName="Errors"
                />
              </Grid>
              <Grid
                item
                style={{
                  width: '92vw',
                }}
              >
                <DataTable
                  scrollable="scrollable"
                  data={processmentDataError}
                  gridColumns={getGridColumns()}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Content>
    </Box>
  )
}

export default PostPlacementResult
