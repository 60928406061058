import { authUrl, clientId, rmsClientId } from 'src/configs/authConst'
import { BASE_ROUTE } from 'src/presentation/routes'
import { Permissions } from '../context/Interfaces'

export const isUkCountry = (country: string | undefined) => {
  return country !== undefined ? country.toUpperCase() === 'UK' : false
}
export interface Options {
  label: string
  value: string
}
export interface RowProps {
  [field: string]: any
}
export interface ActionsMenu {
  onClick: (row: RowProps) => void
  name: string
  loading?: boolean
}

export interface CellTableActionsProps {
  rowProps: any
  actionsMenu: ActionsMenu[]
}

export interface CommomInputProps {
  name?: string
  isForm?: boolean
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export const isRMSEnabled = (): boolean => {
  return process.env.REACT_APP_RMS_ENABLED === 'Y'
}

export const getStandardUri = (uri: string): string => {
  if (uri) {
    const baseRoute = isRMSEnabled() ? BASE_ROUTE : ''
    return `${baseRoute}${uri}`
  }

  return uri
}

export const isRecoverRoute = (pathName: string) => {
  return pathName.includes(`${BASE_ROUTE}/`)
}

interface PermissionReduce {
  [field: string]: boolean
}

export const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce => {
  return permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )
}

export const getLocalStorageUser = (): string | null => {
  const standardClientId = isRMSEnabled() ? rmsClientId : clientId

  return window.localStorage.getItem(
    `dt.user:${authUrl}:${standardClientId}` || ''
  )
}

export const handleBulkSelectRows = (
  e: any,
  accountsData: any[],
  accountsSelected: any[],
  setAccountsSelected: (accounts: any[]) => void,
  idFieldName: string = 'id'
) => {
  if (e === 'Select all rows (current page)') {
    const accountsId = accountsData.map((x: any) => x[idFieldName])

    if (accountsId && accountsId?.length > 0) {
      const allIds = accountsId.concat(accountsSelected)
      const dataSelected = [...new Set([...allIds, ...accountsSelected])]

      if (dataSelected !== undefined && dataSelected.length > 0)
        setAccountsSelected(dataSelected)
    }
  }

  if (e === 'Unselect all rows (current page)') {
    const accountsId = accountsData.map((x: any) => x[idFieldName])
    const ids = accountsSelected.filter((x) => !accountsId?.includes(x))

    if (ids !== undefined) setAccountsSelected(ids)
  }
  if (e === 'Unselect all rows (all pages)') {
    setAccountsSelected([])
  }
}
