import React, { useState } from 'react'
import {
  DataTable,
  DataTableDetailRowProps,
  DataTableState,
  Grid,
  renderDateTime,
  renderExpanded,
} from 'everchain-uilibrary'
import { AuditResponse } from 'src/domain/models/audit'
import { IAuditGetOperations } from 'src/domain/features/get/audit/audit'
import { useCustomQuery } from 'src/infra/reactQuery'
import { TdNoEllipsis } from 'src/presentation/styles/layout'
import { formatAuditTableName } from './AuditInfoHelper'

type AuditGroupType = 'Complaint'

export interface AuditInfoProps {
  auditGetOperations?: IAuditGetOperations
  groupKey: number
  auditGroup: AuditGroupType
  pageSize?: number
  showSection?: boolean
  showTableName?: boolean
}

const AuditInfo: React.FC<AuditInfoProps> = ({
  auditGetOperations,
  groupKey,
  auditGroup,
  pageSize = 10,
  showSection = true,
  showTableName = false,
}: AuditInfoProps) => {
  const [total, setTotal] = useState<number>(0)
  const [auditResponses, setAuditResponses] = useState<AuditResponse[]>()
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: pageSize,
    filter: undefined,
    sort: undefined,
  })

  const gridColumns: any[] = [
    {
      title: ' ',
      field: 'expanded',
      show: true,
      render: (props: any) =>
        renderExpanded(props, expandRowChange, 'auditColumnResponses'),
      width: '40px',
    },
    {
      field: 'dateTimeUtc',
      title: 'Date',
      show: true,
      width: '170px',
      render: renderDateTime,
    },
    {
      field: 'tableName',
      title: 'Table Name',
      show: showTableName,
      width: '200px',
    },
    {
      title: 'Section',
      show: showSection,
      width: '200px',
      render: (props: any) => {
        return (
          <TdNoEllipsis>
            {formatAuditTableName(props.dataItem.tableName)}
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'action',
      title: 'Action',
      width: '100px',
      show: true,
    },
    {
      field: 'userDisplayName',
      title: 'User',
      show: true,
      width: '100px',
    },
  ]

  const dataColumnsDetail = [
    {
      field: 'column',
      title: 'Field',
      show: true,
      width: '40px',
    },
    {
      field: 'oldValue',
      title: 'Old Value',
      show: true,
      width: '40px',
    },
    {
      field: 'newValue',
      title: 'New Value',
      show: true,
      width: '40px',
    },
  ]

  const { isFetching: loadingAuditData } = useCustomQuery(
    ['getPreview', groupKey, auditGroup, gridState],
    async () =>
      auditGetOperations
        ?.getByGroup(groupKey, auditGroup, gridState)
        .then((response) => {
          setAuditResponses(response.data)
          setTotal(response.totalCount)

          return response
        }),
    { cacheTime: 0, enabled: !!groupKey && !!auditGroup }
  )

  const expandRowChange = (dataItem: AuditResponse) => {
    const newItem: AuditResponse = {
      id: dataItem.id,
      userDisplayName: dataItem.userDisplayName,
      tableName: dataItem.tableName,
      action: dataItem.action,
      dateTimeUtc: dataItem.dateTimeUtc,
      auditColumnResponses: dataItem.auditColumnResponses,
      expanded: !dataItem.expanded,
    }

    if (auditResponses) {
      const indexToRemove = auditResponses.findIndex(
        (f) => f.id === dataItem.id
      )

      const oldData = auditResponses.filter((f) => f.id !== dataItem.id)
      oldData.splice(indexToRemove, 0, newItem)
      const newData = oldData

      console.log('newData', newData)

      setAuditResponses(newData)
    }
  }

  const AuditDetails = (props: DataTableDetailRowProps) => {
    const { dataItem } = props
    return (
      <DataTable
        data={dataItem.auditColumnResponses}
        gridColumns={dataColumnsDetail}
      />
    )
  }

  return (
    <Grid xs={12}>
      <DataTable
        expandField="expanded"
        height="100%"
        maxHeight="100%"
        isLoading={loadingAuditData}
        detail={AuditDetails}
        gridColumns={gridColumns}
        gridState={gridState}
        data={auditResponses ?? []}
        pageable={true}
        total={total}
        onDataStateChange={(e) => {
          setGridState(e.dataState)
        }}
      />
    </Grid>
  )
}

export default AuditInfo
