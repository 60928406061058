import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { BlobServiceClient } from '@azure/storage-blob'
import {
  Box,
  Button,
  Content,
  ErrorToast,
  Grid,
  Icon,
  IconButton,
  ModalDialog,
  SuccessToast,
  textSecondary,
  Typography,
} from 'everchain-uilibrary'
import { v4 as uuidv4 } from 'uuid'
import { DropUpload, UploadItem } from 'src/presentation/styles/mediaUpload'
import { DropzoneErrorCodes } from 'src/utils/constants'
import LinearProgressWithLabel from '../../MediaUpload/ProgressBar/LinearProgressWithLabel'
import { IMediaUploadGetOperations } from 'src/domain/features/get/mediaUpload/mediaUpload'
import { IMediaUploadPostOperations } from 'src/domain/features/post/mediaUpload/mediaUpload'
import { useMutation } from '@tanstack/react-query'

interface AccountDetailMediaFilesFormProps {
  mediaUploadGetOperations: IMediaUploadGetOperations
  mediaUploadPostOperations: IMediaUploadPostOperations
  eCAID: string | undefined
  mediaFilesData?: any
  setUploadInProgress: any
  mediaFilesRefetch: () => void
}
const AccountDetailMediaFilesForm: React.FC<
  AccountDetailMediaFilesFormProps
> = ({
  mediaUploadGetOperations,
  mediaUploadPostOperations,
  eCAID,
  mediaFilesData,
  setUploadInProgress,
  mediaFilesRefetch,
}) => {
  const [mediaFiles, setMediaFiles] = useState<any[]>([])
  const [progress, setProgress] = useState(0)
  const [openConfirmReplaceDialog, setOpenConfirmReplaceDialog] =
    useState<boolean>(false)
  const [loadingSaveAccountCardMediaFile, setLoadingSaveAccountCardMediaFile] =
    useState<boolean>(false)
  const [
    loadingSaveAccountCardMediaFileDetails,
    setLoadingSaveAccountCardMediaFileDetails,
  ] = useState<boolean>(false)
  const RecoverMediaFileAccountContainer = 'recover'

  const MAX_SIZE_FILE = 524288000 // 500mb
  const onDrop = useCallback((acceptedFiles: any) => {
    setMediaFiles(acceptedFiles)
  }, [])

  const handleFileRejected = (data: any) => {
    const message =
      data[0].errors[0].code === DropzoneErrorCodes.INVALID_TYPE_FILE
        ? 'Only .zip files are accepted.'
        : data[0].errors[0].code === DropzoneErrorCodes.FILE_TOO_BIG
        ? 'File is over the 500MB limit.'
        : ''

    ErrorToast(`The file has been rejected. ${message}`)
  }

  const fileLinks = mediaFilesData ?? []

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    minSize: 500,
    maxSize: MAX_SIZE_FILE,
    accept:
      'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip,.zip',
    onDropRejected: handleFileRejected,
  })

  function handleUploadFileRemove(): void {
    setMediaFiles([])
  }

  const blobStorageUrls = async () => {
    const urls = await mediaUploadGetOperations?.getMediaFileBlobUrl()
    return urls
  }

  const saveAccountDataMediaFileDetails = useMutation({
    mutationFn: async (params: any) => {
      setLoadingSaveAccountCardMediaFileDetails(true)
      return mediaUploadPostOperations?.saveAccountMediaFile(params)
    },
    onSuccess: (data: any) => {
      setLoadingSaveAccountCardMediaFileDetails(false)
      SuccessToast('File uploaded successfully')
      setMediaFiles([])
      cancelOperation()
      mediaFilesRefetch()
    },
    onError: (exceptcion) => {
      setLoadingSaveAccountCardMediaFileDetails(false)
      ErrorToast('Error uploading files!')
      cancelOperation()
    },
  })

  const cancelOperation = () => {
    setLoadingSaveAccountCardMediaFile(false)
    setUploadInProgress(false)
  }

  const saveMediaFile = async () => {
    let overwrite = false
    if (fileLinks?.length > 0) {
      overwrite =
        fileLinks.filter((e: any) => e.displayName === mediaFiles[0].name)
          .length > 0
    }

    if (overwrite) {
      setOpenConfirmReplaceDialog(true)
    } else {
      handleUploadFile(overwrite)
    }
  }

  const handleClose = () => {
    setOpenConfirmReplaceDialog(false)
  }

  const handleConfirmFileReplace = () => {
    handleClose()
    handleUploadFile(true)
  }

  const handleUploadFile = async (overwrite: any) => {
    const uuid = uuidv4()
    const blobName = `${'mediaFiles'}/${uuid}/${mediaFiles[0].name}`

    blobStorageUrls().then(async (data: any) => {
      if (!data.getMediaFileBlobUrl) {
        ErrorToast('Error uploading files')
        return
      }
      setLoadingSaveAccountCardMediaFile(true)
      const blobStorageClient = new BlobServiceClient(data?.getMediaFileBlobUrl)

      const containerClient = blobStorageClient.getContainerClient(
        RecoverMediaFileAccountContainer
      )
      const blockBlobClient = containerClient.getBlockBlobClient(blobName)

      await blockBlobClient
        .uploadData(mediaFiles[0], {
          blobHTTPHeaders: { blobContentType: 'application/x-zip-compressed' },
          onProgress(fileProgress: any) {
            const percentageUploaded =
              (fileProgress?.loadedBytes / mediaFiles[0].size) * 100
            setProgress(percentageUploaded)
          },
        })
        .then(() => {
          saveAccountDataMediaFileDetails.mutate({
            eCAID,
            overwrite,
            blobUri: blobName,
            request: {
              fileName: mediaFiles[0].name,
              contentType: mediaFiles[0].type,
            },
          })
        })
        .catch((error) => {
          cancelOperation()
          ErrorToast('Error uploading files')
        })
    })
  }

  return (
    <Content paddingBottom={0} paddingTop={0}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {loadingSaveAccountCardMediaFile ||
          loadingSaveAccountCardMediaFileDetails ? (
            <LinearProgressWithLabel value={progress} />
          ) : !mediaFiles.length ? (
            <>
              <DropUpload isDragActive={isDragActive} {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="upload-placeholder">
                  <Icon name="CloudUpload" className="upload-icon" />{' '}
                  <strong>Choose File </strong> or drop a file here to upload
                </div>
              </DropUpload>
              <Typography variant="caption" color={'black !important'}>
                Maximum allowed file size is <strong>500MB</strong>
              </Typography>
            </>
          ) : (
            <UploadItem>
              {mediaFiles.map((file: any, idx) => (
                <>
                  <div className="upload-item-info">
                    <Icon
                      name="Description"
                      fontSize="small"
                      color="primary"
                      className="upload-item-icon"
                    />{' '}
                    <Typography
                      variant="body2"
                      color={textSecondary.color}
                      key={file.name}
                    >
                      {file.name}
                    </Typography>
                  </div>
                  <IconButton
                    aria-label="Clear file selection"
                    onClick={handleUploadFileRemove}
                  >
                    <Icon name="Delete" fontSize="small" />
                  </IconButton>
                </>
              ))}
            </UploadItem>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" mr={2}>
            <Button
              data-cy="add-attachment-button"
              type="submit"
              disabled={
                !mediaFiles.length ||
                loadingSaveAccountCardMediaFile ||
                loadingSaveAccountCardMediaFileDetails
              }
              onClick={() => saveMediaFile()}
              leftIcon={<Icon name="Add" fontSize="small" />}
            >
              Add File
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ModalDialog
        isOpen={openConfirmReplaceDialog}
        header="Replace file"
        onClose={handleClose}
        onContinue={handleConfirmFileReplace}
        isFetching={loadingSaveAccountCardMediaFile}
        disableOkButton={loadingSaveAccountCardMediaFile}
        buttonOkText="Confirm"
        width="25%"
      >
        <Typography variant="body1" color={textSecondary.color}>
          {
            ' You already have a file with that name, do you want to replace it?'
          }
        </Typography>
      </ModalDialog>
    </Content>
  )
}

export default AccountDetailMediaFilesForm
