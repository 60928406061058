import React, { useContext, useState } from 'react'
import {
  Button,
  ButtonDropdown,
  ButtonDropdownItem,
  Group,
} from 'everchain-uilibrary'
import LoadAccounts from '../LoadAccounts/LoadAccountsModal'
import { makeBusinessGetData } from 'src/main/factories/feature/get/Business'
import { makeStrategyData } from 'src/main/factories/feature/get/Strategy'
import { generateFileBlob } from 'src/utils/file/fileGenerator'
import LoadRecallAccountsModal from '../LoadRecallAccounts/LoadRecallAccountsModal'
import {
  AccountRetrievalType,
  FileTypeEnum,
  PermissionCodeAccess,
} from 'src/utils/constants'
import { AbilityContext, Can } from 'src/context/Can'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import LoadTransactionModal from '../LoadTransactions/LoadTransactionModal'
import RemittanceReportModal from '../RemittanceReport/RemittanceReportModal'
import { makeReportData } from 'src/main/factories/feature/get/Report'
import { makeAccountPostData } from 'src/main/factories/feature/post/Account'
import UpdateAccountsModal from '../UpdateAccounts/UpdateAccountsModal'
import { useCustomQuery } from 'src/infra/reactQuery'
import { AuthContext } from 'src/context/AuthenticationContext'

interface LoadActionParams {
  fileOperations?: IFileGetOperations
}

const LoadActions: React.FC<LoadActionParams> = ({ fileOperations }) => {
  const [openLoadAccountsModal, setOpenLoadAccountsModal] = useState(false)
  const [openLoadRecallAccountsModal, setOpenLoadRecallAccountsModal] =
    useState(false)
  const [openLoadTransactionsModal, setOpenLoadTransactionsModal] =
    useState(false)
  const [openRemittanceReportModal, setOpenRemittanceReportModal] =
    useState(false)
  const [openUpdateAccountsModal, setOpenUpdateLoadAccountsModal] =
    useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<number>()

  const ability = useContext(AbilityContext)
  const { isVendor, isCreditor } = useContext(AuthContext)

  const canCreditor =
    ability.can(PermissionCodeAccess.Recover_RecallAccounts, 'any') &&
    isCreditor

  const canVendor =
    ability.can(PermissionCodeAccess.Recover_ReturnAccounts, 'any') && isVendor

  const canUpdateAccounts = ability.can(
    PermissionCodeAccess.Recover_UpdateAccountInformation,
    'any'
  )

  const canLoadAccounts = ability.can(
    PermissionCodeAccess.Recover_LoadAccountInformation,
    'any'
  )

  const { isFetching: loadingTemplate } = useCustomQuery(
    ['getTemplateFile', selectedTemplate],
    async () =>
      fileOperations?.getTemplateFile(selectedTemplate).then((template) => {
        setSelectedTemplate(undefined)
        generateFileBlob(template)
      }),
    { cacheTime: 0, enabled: !!selectedTemplate }
  )

  const downloadTemplate = async (templateType: number) => {
    try {
      setSelectedTemplate(templateType)
    } catch (err) {
      console.error(err)
    }
  }

  const getRecallActionName = () => {
    return isVendor ? 'Return Accounts' : 'Recall Accounts'
  }

  return (
    <>
      <Group>
        {canLoadAccounts && (
          <ButtonDropdown
            buttontext="Load Accounts"
            variant="primary"
            disabled={loadingTemplate}
            onClickButton={() => setOpenLoadAccountsModal(true)}
            width="170px"
          >
            <ButtonDropdownItem
              onClick={() => downloadTemplate(FileTypeEnum.AccountFile)}
            >
              Download Template
            </ButtonDropdownItem>
          </ButtonDropdown>
        )}
        {canUpdateAccounts && (
          <ButtonDropdown
            buttontext="Update Accounts"
            variant="primary"
            disabled={loadingTemplate}
            onClickButton={() => setOpenUpdateLoadAccountsModal(true)}
            width="170px"
          >
            <ButtonDropdownItem
              onClick={() => downloadTemplate(FileTypeEnum.UpdateAccountFile)}
            >
              Download Template
            </ButtonDropdownItem>
          </ButtonDropdown>
        )}
        {(canCreditor || canVendor) && (
          <ButtonDropdown
            buttontext={getRecallActionName()}
            variant="primary"
            disabled={loadingTemplate}
            onClickButton={() => setOpenLoadRecallAccountsModal(true)}
            width="170px"
          >
            <ButtonDropdownItem
              onClick={() => downloadTemplate(FileTypeEnum.RecallAccountFile)}
            >
              Download Template
            </ButtonDropdownItem>
          </ButtonDropdown>
        )}
        <Can
          do={PermissionCodeAccess.Recover_AllowBalanceAdjustmentsUpload}
          on="any"
        >
          {(canCreditor || canVendor) && (
            <ButtonDropdown
              buttontext="Balance Adjustments"
              width="200px"
              variant="primary"
              disabled={loadingTemplate}
              style={{ alignContent: 'center' }}
              onClickButton={() => setOpenLoadTransactionsModal(true)}
            >
              <ButtonDropdownItem
                onClick={() => downloadTemplate(FileTypeEnum.BalanceAdjustment)}
              >
                Download Template
              </ButtonDropdownItem>
            </ButtonDropdown>
          )}
        </Can>
        <Button
          variant="primary"
          disabled={false}
          onClick={() => setOpenRemittanceReportModal(true)}
          width="170px"
          hidden={true}
        >
          Remittance Report
        </Button>
      </Group>
      <LoadAccounts
        open={openLoadAccountsModal}
        setOpenLoadModal={setOpenLoadAccountsModal}
        businessOperations={makeBusinessGetData()}
        strategyOperations={makeStrategyData()}
      />
      <LoadRecallAccountsModal
        open={openLoadRecallAccountsModal}
        setOpenLoadModal={setOpenLoadRecallAccountsModal}
        modalTitle={getRecallActionName()}
        businessOperations={makeBusinessGetData()}
        operationType={
          isVendor ? AccountRetrievalType.Return : AccountRetrievalType.Recall
        }
        accountPostOperations={makeAccountPostData()}
      />
      <LoadTransactionModal
        open={openLoadTransactionsModal}
        setOpenLoadModal={setOpenLoadTransactionsModal}
        businessOperations={makeBusinessGetData()}
      />
      <RemittanceReportModal
        open={openRemittanceReportModal}
        setOpenLoadModal={setOpenRemittanceReportModal}
        businessOperations={makeBusinessGetData()}
        reportOperations={makeReportData()}
      />
      <UpdateAccountsModal
        open={openUpdateAccountsModal}
        setOpenLoadModal={setOpenUpdateLoadAccountsModal}
        businessOperations={makeBusinessGetData()}
      />
    </>
  )
}

export default LoadActions
