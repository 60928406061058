import React from 'react'
import PostPlacementTypeAndExplanationData from './TypeAndExplanationData'
import { PostPlacementTypeEnum } from 'src/utils/constants'
import PostPlacementBankruptData from './BankruptData'
import { Accordion, Icon } from 'everchain-uilibrary'
import PostPlacementDeceasedData from './DeceasedData'
import PostPlacementRetainData from './RetainData'
import PostPlacementInfoData from './InfoData'
import PostPlacementExplanationData from './ExplanationData'
import PostPlacementPaidPriorData from './PaidPriorData'
import PostPlacementLowBalanceData from './LowBalanceData'
import PostPlacementOutOfStatData from './OutOfStatData'
import PostPlacementPifSifData from './PifSifData'
import PostPlacementCreditBureauReportingData from './CreditBureauReportingData'

interface PostPlacementSpecificDataParams {
  postPlacementType: number | undefined
  data: any
  isLoading: boolean
}
const PostPlacementSpecificData: React.FC<PostPlacementSpecificDataParams> = ({
  postPlacementType,
  data,
  isLoading,
}) => {
  const getPostPlacementName = (requestType: number | undefined) => {
    switch (requestType) {
      case PostPlacementTypeEnum.Bankrupt:
        return 'Bankrupt'
      case PostPlacementTypeEnum.Deceased:
        return 'Deceased'
      case PostPlacementTypeEnum.Retain:
        return 'Retain'
      case PostPlacementTypeEnum.Info:
        return 'Information'
      case PostPlacementTypeEnum.Legal:
        return 'Legal'
      case PostPlacementTypeEnum.Other:
        return 'Other'
      case PostPlacementTypeEnum.PaidPrior:
        return 'Paid Prior'
      case PostPlacementTypeEnum.LowBalance:
        return 'Low Balance'
      case PostPlacementTypeEnum.OutOfStat:
        return 'Out Of Stat'
      case PostPlacementTypeEnum.Fraud:
        return 'Fraud'
      case PostPlacementTypeEnum.Compliance:
        return 'Compliance'
      case PostPlacementTypeEnum.PifSif:
        return 'Pif/Sif'
      case PostPlacementTypeEnum.InaccurateData:
        return 'Inaccurate Data'
      case PostPlacementTypeEnum.DebtSettlement:
        return 'Debt Settlement'
      case PostPlacementTypeEnum.CreditBureauReporting:
        return 'Credit Bureau Reporting'
      case PostPlacementTypeEnum.CCCS:
        return 'Consumer Credit Counseling Service'
      case PostPlacementTypeEnum.SCRA:
        return 'SCRA'
      default:
        return ''
    }
  }

  return (
    <Accordion
      icon={<Icon fontSize="17px" name="LibraryBooks" />}
      title={`${getPostPlacementName(postPlacementType)} Request`}
      defaultOpen={true}
      isLoading={isLoading}
    >
      {postPlacementType === PostPlacementTypeEnum.Bankrupt && (
        <PostPlacementBankruptData data={data}></PostPlacementBankruptData>
      )}
      {postPlacementType === PostPlacementTypeEnum.Deceased && (
        <PostPlacementDeceasedData data={data}></PostPlacementDeceasedData>
      )}
      {postPlacementType === PostPlacementTypeEnum.Retain && (
        <PostPlacementRetainData data={data}></PostPlacementRetainData>
      )}
      {postPlacementType === PostPlacementTypeEnum.Info && (
        <PostPlacementInfoData data={data}></PostPlacementInfoData>
      )}
      {(postPlacementType === PostPlacementTypeEnum.Legal ||
        postPlacementType === PostPlacementTypeEnum.Fraud ||
        postPlacementType === PostPlacementTypeEnum.Compliance ||
        postPlacementType === PostPlacementTypeEnum.SCRA) && (
        <PostPlacementTypeAndExplanationData
          data={data}
        ></PostPlacementTypeAndExplanationData>
      )}
      {(postPlacementType === PostPlacementTypeEnum.Other ||
        postPlacementType === PostPlacementTypeEnum.InaccurateData ||
        postPlacementType === PostPlacementTypeEnum.DebtSettlement ||
        postPlacementType === PostPlacementTypeEnum.CCCS) && (
        <PostPlacementExplanationData
          data={data}
        ></PostPlacementExplanationData>
      )}
      {postPlacementType === PostPlacementTypeEnum.PaidPrior && (
        <PostPlacementPaidPriorData data={data}></PostPlacementPaidPriorData>
      )}
      {postPlacementType === PostPlacementTypeEnum.LowBalance && (
        <PostPlacementLowBalanceData data={data}></PostPlacementLowBalanceData>
      )}
      {postPlacementType === PostPlacementTypeEnum.OutOfStat && (
        <PostPlacementOutOfStatData data={data}></PostPlacementOutOfStatData>
      )}
      {postPlacementType === PostPlacementTypeEnum.PifSif && (
        <PostPlacementPifSifData data={data}></PostPlacementPifSifData>
      )}
      {postPlacementType === PostPlacementTypeEnum.CreditBureauReporting && (
        <PostPlacementCreditBureauReportingData
          data={data}
        ></PostPlacementCreditBureauReportingData>
      )}
    </Accordion>
  )
}

export default PostPlacementSpecificData
