import React, { useContext, useState } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  Box,
  Content,
  DataTable,
  Icon,
  IconButton,
  ModalDialog,
  SuccessToast,
  textSecondary,
  Typography,
} from 'everchain-uilibrary'
import { MediaFileDetailsData } from 'src/domain/models/mediaFiles'
import ZipIcon from 'src/presentation/components/Icons/ZipIcon'
import { ConvertDateToUTC, formatDateAndTimeCountry } from 'src/utils/date'
import { makeMediaDownloadGetData } from 'src/main/factories/feature/get/MediaDownload'
import { cellCreateBy } from 'src/utils/gridHelper'
import { Button } from '@material-ui/core'

interface AccountDetailMediaFilesTableProps {
  mediaFilesData?: MediaFileDetailsData[] | undefined
  loadingTable: boolean
  uploadInProgress: boolean
  mediaFilesRefetch: () => void
}

const AccountDetailMediaFilesTable: React.FC<
  AccountDetailMediaFilesTableProps
> = ({
  mediaFilesData,
  loadingTable,
  uploadInProgress,
  mediaFilesRefetch,
}: AccountDetailMediaFilesTableProps) => {
  const [mediaFileToBeDeleted, setMediaFileToBeDeleted] = useState<any>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const { profileClient } = useContext<any>(AuthContext)
  const mediaDownloadGetOperations = makeMediaDownloadGetData()
  const [loadingDeleteMediaFile, setLoadingDeleteMediaFile] =
    useState<boolean>(false)
  const [loadingDownloadMediaFile, setLoadingDownloadMediaFile] =
    useState<boolean>(false)

  const onDownload = async (mediaFileId: any) => {
    setLoadingDownloadMediaFile(true)

    const mediaFile = await mediaDownloadGetOperations?.getMediaFile(
      mediaFileId
    )
    downloadFile(mediaFile.fileUri)
    setLoadingDownloadMediaFile(false)
    SuccessToast('File Downloaded Successfully')
  }

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const handleDeleteDialogToggle = () => {
    setDeleteDialogOpen(!deleteDialogOpen)
  }

  const handleDeleteMediaFile = (value: any) => {
    setMediaFileToBeDeleted(value)
    handleDeleteDialogToggle()
  }

  const onDelete = async () => {
    setLoadingDeleteMediaFile(true)

    await mediaDownloadGetOperations?.deleteMediaFile(mediaFileToBeDeleted)
    setLoadingDeleteMediaFile(false)
    SuccessToast('File Deleted Successfully')
    mediaFilesRefetch()
    handleDeleteDialogToggle()
  }

  const fileLinkColumns: any[] = [
    {
      title: 'Document Type',
      field: 'documentType',
      show: true,
      width: 60,
      render: (props: any) => {
        return props.dataItem[props.field] ? (
          <td {...props} data-cy="document-type-field">
            <span>
              {props.dataItem[props.field] !== ''
                ? props.dataItem[props.field]
                : 'N/A'}
            </span>
          </td>
        ) : (
          <td data-cy="document-type-field">
            <span>N/A</span>
          </td>
        )
      },
    },
    {
      title: 'Original File Name',
      field: 'displayName',
      width: 150,
      show: true,
      render: (props: any) => {
        return (
          <td>
            <Box width={300}>
              <Button
                data-cy="file-name-button"
                disableRipple={true}
                style={{ backgroundColor: 'transparent' }}
                startIcon={
                  <Box width={20} height={20}>
                    <ZipIcon width="130%" height="130%" />
                  </Box>
                }
                onClick={() => {
                  onDownload(props.dataItem.id)
                }}
              >
                {props.dataItem[props.field]}
              </Button>
            </Box>
          </td>
        )
      },
    },
    {
      title: 'Uploaded By',
      field: 'createdByUserName',
      width: 150,
      show: true,
      render: (props: any) => cellCreateBy(props),
    },
    {
      title: 'Uploaded Date',
      field: 'createdAt',
      width: 100,
      show: true,
      render: (props: any) => {
        return props.dataItem[props.field] ? (
          <td data-cy="uploaded-date-field">
            <span>
              {props.dataItem[props.field] !== ''
                ? formatDateAndTimeCountry(
                    new Date(ConvertDateToUTC(props.dataItem[props.field])),
                    profileClient?.Country || process.env.REACT_APP_COUNTRY
                  )
                : 'N/A'}
            </span>
          </td>
        ) : (
          <td data-cy="uploaded-date-field">
            <span>N/A</span>
          </td>
        )
      },
    },
    {
      title: 'Is Deleted',
      field: 'isDeleted',
      width: 50,
      show: true,
      render: (props: any) => {
        return (
          <td data-cy="is-deleted">
            <span>{props.dataItem[props.field] === true ? 'Yes' : 'No'}</span>
          </td>
        )
      },
    },
    {
      title: 'Actions',
      field: '',
      show: true,
      width: 40,
      alignCenter: true,
      render: (props: any) => {
        return (
          <td>
            <Box
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton
                disabled={props.dataItem.isDeleted}
                onClick={() => {
                  handleDeleteMediaFile(props.dataItem.id)
                }}
              >
                <Icon name="Delete" fontSize="small" />
              </IconButton>
            </Box>
          </td>
        )
      },
    },
  ]

  return (
    <Content paddingTop={2}>
      <DataTable
        scrollable="scrollable"
        data={mediaFilesData}
        isLoading={
          loadingTable ||
          loadingDownloadMediaFile ||
          loadingDeleteMediaFile ||
          uploadInProgress
        }
        gridColumns={fileLinkColumns}
      />
      <ModalDialog
        isOpen={deleteDialogOpen}
        header="Confirm delete media file"
        onClose={handleDeleteDialogToggle}
        onContinue={onDelete}
        isFetching={loadingDeleteMediaFile}
        disableOkButton={loadingDeleteMediaFile}
        buttonOkText="Yes"
        buttonCancelText="No"
        width="25%"
      >
        <Typography variant="body1" color={textSecondary.color}>
          Are you sure you want to delete this media file?
        </Typography>
      </ModalDialog>
    </Content>
  )
}

export default AccountDetailMediaFilesTable
