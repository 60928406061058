export enum AuditTable {
  ComplaintInfoRequest = 'ComplaintInfoRequest',
  ComplaintComment = 'ComplaintComment',
  ComplaintAttachment = 'ComplaintAttachment',
}

export const formatAuditTableName = (table: AuditTable): string => {
  const tableNames: Record<AuditTable, string> = {
    [AuditTable.ComplaintInfoRequest]: 'Additional Info Request',
    [AuditTable.ComplaintComment]: 'Comments',
    [AuditTable.ComplaintAttachment]: 'Files',
  }

  return tableNames[table] || 'General Info'
}
