import React from 'react'
import { Display } from '../styles'
import { AccountDetailsData } from 'src/domain/models/accounts'
import {
  Box,
  Card,
  Content,
  Summary,
  Typography,
  fFormatDate,
  fNumberCurrency,
  textSecondary,
  Accordion,
  Icon,
} from 'everchain-uilibrary'
import { profileCountry } from 'src/utils/user'

interface DisplayAggregatesProps {
  account: AccountDetailsData | undefined
  isLoading: boolean
}

export interface SummaryValue {
  header: string
  value: string | null
  order: number
}

const DisplayAggregates: React.FC<DisplayAggregatesProps> = ({
  account,
  isLoading,
}: DisplayAggregatesProps) => {
  const aggregateBlock: SummaryValue[] = [
    {
      header: 'Original Balance',
      value: fNumberCurrency(
        account?.originalTotalBalance
          ? account?.originalTotalBalance.toFixed(2)
          : 0,
        profileCountry()
      ),
      order: 0,
    },
    {
      header: 'Recovered Balance',
      value: fNumberCurrency(
        account?.totalRecoveryPayments
          ? (account?.totalRecoveryPayments).toFixed(2)
          : 0,
        profileCountry()
      ),
      order: 1,
    },
    {
      header: 'Current Balance',
      value: fNumberCurrency(
        typeof account?.currentTotalBalance === 'number'
          ? account?.currentTotalBalance.toFixed(2)
          : '',
        profileCountry()
      ),
      order: 2,
    },
  ]

  const aggregateCurrentGrid: SummaryValue[] = [
    {
      header: 'Is account Charged-Off',
      value: account?.isChargedOff ? 'Yes' : 'No',
      order: 1,
    },
    {
      header: 'Last Activity',
      value: account?.lastActivity ? account?.lastActivity : 'N/A',
      order: 3,
    },
    {
      header: 'Account Status',
      value: account?.lastActivity ? account?.accountStatus : 'N/A',
      order: 4,
    },
    {
      header: 'Principal Balance',
      value: fNumberCurrency(
        account?.isChargedOff
          ? account?.postChargeOffPrincipalBalance
            ? account?.postChargeOffPrincipalBalance.toFixed(2)
            : 0
          : account?.principalBalance
          ? account?.principalBalance.toFixed(2)
          : 0,
        profileCountry()
      ),
      order: 5,
    },
    {
      header: 'Interest Balance',
      value: fNumberCurrency(
        account?.isChargedOff
          ? account?.postChargeOffInterestBalance
            ? account?.postChargeOffInterestBalance.toFixed(2)
            : 0
          : account?.interestBalance
          ? account?.interestBalance.toFixed(2)
          : 0,
        profileCountry()
      ),
      order: 6,
    },
    {
      header: 'Fee Balance',
      value: fNumberCurrency(
        account?.isChargedOff
          ? account?.postChargeOffFeeBalance
            ? account?.postChargeOffFeeBalance.toFixed(2)
            : 0
          : account?.feeBalance
          ? account?.feeBalance.toFixed(2)
          : 0,
        profileCountry()
      ),
      order: 7,
    },
  ]

  const summaryCurrentDetails = {
    gridItemValues: aggregateCurrentGrid,
    blockValues: aggregateBlock,
  }

  const summaryData = {
    summaryDetails: [summaryCurrentDetails],
  }

  return (
    <Display data-test-id="display-aggregates">
      <Card
        variant="secondary"
        content={
          <Content padding={3}>
            <Accordion
              title="Account Info"
              defaultOpen={true}
              isLoading={isLoading}
              icon={<Icon name="Description" />}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                  <Typography color={textSecondary.color}>
                    Current Placer:
                  </Typography>
                  <Typography color={textSecondary.color}>
                    {account?.lender ? account?.lender : 'N/A'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                  <Typography color={textSecondary.color}>
                    Current Vendor:
                  </Typography>
                  <Typography color={textSecondary.color}>
                    {account?.currentVendorName
                      ? account?.currentVendorName
                      : 'N/A'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                  <Typography color={textSecondary.color}>
                    Default Date:
                  </Typography>
                  <Typography color={textSecondary.color}>
                    {account?.defaultDate
                      ? fFormatDate(account?.defaultDate)
                      : 'N/A'}
                  </Typography>
                </Box>
                {account?.isChargedOff && (
                  <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                    <Typography color={textSecondary.color}>
                      Write-Off Date:
                    </Typography>
                    <Typography color={textSecondary.color}>
                      {account?.writeOffDate
                        ? fFormatDate(account?.writeOffDate)
                        : 'N/A'}
                    </Typography>
                  </Box>
                )}
                <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                  <Typography color={textSecondary.color}>
                    First Delinquency Date:
                  </Typography>
                  <Typography color={textSecondary.color}>
                    {account?.firstDelinquencyDate
                      ? fFormatDate(account?.firstDelinquencyDate)
                      : 'N/A'}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                  <Typography color={textSecondary.color}>
                    Origination Date:
                  </Typography>
                  <Typography color={textSecondary.color}>
                    {account?.fundDate ? fFormatDate(account?.fundDate) : 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </Accordion>
            <Summary
              summaryData={summaryData}
              displayFilters={false}
              isLoading={isLoading}
            ></Summary>
          </Content>
        }
      ></Card>
    </Display>
  )
}

export default DisplayAggregates
