import React from 'react'
import { ThemeProvider } from 'styled-components'
import {
  CssBaseline,
  unstable_createMuiStrictModeTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import DateFnsUtils from '@date-io/date-fns'
import enLocale from 'date-fns/locale/en-US'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { GlobalStyle, defaultTheme } from 'src/presentation/styles'

import App from './App'
import {
  SessionExpiringModal,
  SessionInactivityModal,
  ToastContainer,
} from 'everchain-uilibrary'
import AuthService from 'src/configs/AuthService'
import { OldAuthProvider } from 'src/context/OldAuthenticationContext'
import { RecoverGridFilterProvider } from 'src/context/RecoverGridFilterContext'

const localeMap = {
  en: enLocale,
}

const locale = 'en'

const createMuiTheme = unstable_createMuiStrictModeTheme

const theme = createMuiTheme(defaultTheme)
const themeResponsiveFonts = responsiveFontSizes(theme)

const Root: React.FC = () => {
  const auth = new AuthService()
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={themeResponsiveFonts}>
        <ThemeProvider theme={themeResponsiveFonts}>
          <CssBaseline />
          <GlobalStyle />
          <Router>
            <ToastContainer />
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={localeMap[locale]}
            >
              <SessionExpiringModal
                expiringAccessToken={auth.expiringAccessToken}
                expiredAccessToken={auth.expiredAccessToken}
              />
              <SessionInactivityModal onLogout={auth.logout} />
              <OldAuthProvider>
                <RecoverGridFilterProvider>
                  <App />
                </RecoverGridFilterProvider>
              </OldAuthProvider>
            </MuiPickersUtilsProvider>
          </Router>
        </ThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  )
}

export default Root
