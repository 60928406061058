/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { AbilityContext } from 'src/context/Can'
import { Box, Grid } from '@material-ui/core'
import React, { useContext, useState, useEffect } from 'react'
import Creditors from '../Accounts/components/Creditors'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import {
  Button,
  DataTable,
  DataTableState,
  renderDate,
  renderCurrency,
  Switch,
  useForm,
  Dropdown,
  fNumberCurrency,
  ButtonDropdown,
  ButtonDropdownItem,
  ModalDialog,
  Colors,
  Typography,
  UploadDragDrop,
  Step,
  renderCheckbox,
  SuccessToast,
  WarningToast,
  fParseXlsxFile,
  Group,
  SwitchType,
} from 'everchain-uilibrary'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import DateAccountFilter from '../Accounts/components/DateAccountFilters'
import { Account } from 'src/domain/models/accounts'
import { useHistory } from 'react-router-dom'
import {
  ACCOUNT_DETAIL,
  CHARGE_OFF_RESTING_ACCOUNT_FILE_VALIDATION,
} from 'src/presentation/routes'

import { profileCountry } from 'src/utils/user'
import { AuthContext } from 'src/context/AuthenticationContext'

import { getStandardUri, handleBulkSelectRows } from 'src/utils/common'
import { IAssetTypesGetOperations } from 'src/domain/features/get/assetTypes/assetTypes'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { dateThreeMonthsPast } from 'src/utils/date'
import { generateFileBlob } from 'src/utils/file/fileGenerator'
import {
  ChargeOffBalanceEnum,
  FileTypeEnum,
  PermissionCodeAccess,
} from 'src/utils/constants'
import { useCustomQuery } from 'src/infra/reactQuery'
import { IPortfolioGetOperations } from 'src/domain/features/get/portfolio/portfolio'
import UploadModal from '../Accounts/MediaUpload/UploadModal'
import Placers from '../Accounts/components/Placers'
import Page from '../Common/Page'
import { useRecoverGridFilter } from 'src/context/RecoverGridFilterContext'
import SellAccountsModal from './components/SellAccountsModal'

interface ChargeOffBalance {
  addInterestBalancetoPostChargeOffPrincipal: boolean
  zeroOutInterestBalanceAtChargeOff: boolean
  addFeeBalanceToPostChargeOffPrincipal: boolean
  zeroOutFeeBalanceAtChargeOff: boolean
}

interface RestingAreaParams {
  accountOperations?: IAccountGetOperations
  businessOperations?: IBusinessGetOperations
  fileOperations?: IFileGetOperations
  assetTypeOperations?: IAssetTypesGetOperations
  strategyOperations?: IStrategyGetOperations
  accountPostOperations: IAccountPostOperations
  portfolioGetOperations?: IPortfolioGetOperations
}

export interface FormFilter {
  creditorId: string | undefined
  dateAccountFrom?: Date
  dateAccountTo?: Date
  chargeOff?: boolean
  assetTypeId?: string | undefined
  dateTypeFilter?: any
}

const RestingArea: React.FC<RestingAreaParams> = ({
  businessOperations,
  accountOperations,
  fileOperations,
  assetTypeOperations,
  strategyOperations,
  accountPostOperations,
  portfolioGetOperations,
}) => {
  const [assetTypes, setAssetTypes] = useState<any>()
  const [templates, setTemplates] = useState<any>()
  const [formChargeOffBalances, setFormChargeOffBalances] =
    useState<ChargeOffBalance>({
      addInterestBalancetoPostChargeOffPrincipal: false,
      zeroOutInterestBalanceAtChargeOff: false,
      addFeeBalanceToPostChargeOffPrincipal: false,
      zeroOutFeeBalanceAtChargeOff: false,
    })

  const { placerFilter, setPlacerFilter } = useRecoverGridFilter()
  const getPersistedAccountFilterData = () => {
    const data = window.localStorage.getItem('accountsRestingAreaFilterStorage')
    const parsedData = data ? JSON.parse(data) : {}

    return {
      ...parsedData,
      creditorId: placerFilter,
      dateFrom: parsedData.dateFrom || dateThreeMonthsPast(),
      dateTo: parsedData.dateTo || new Date(),
    }
  }
  const getAccountsSelectedData = () => {
    if (window.localStorage.getItem('accountsSelected') !== null) {
      return JSON.parse(window.localStorage.getItem('accountsSelected') ?? '')
    }
    return []
  }

  const getPersistedAccountData = () => {
    if (window.localStorage.getItem('accountsRestingAreaFiltered') !== null) {
      return JSON.parse(
        window.localStorage.getItem('accountsRestingAreaFiltered') ?? ''
      )
    }
  }

  const [openSellModal, setOpenSellModal] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([])
  const [typeUpload, setTypeUpload] = useState<
    'allAccounts' | 'filteredAccounts' | 'file' | undefined
  >(undefined)
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false)
  const [openChargeOffUploadModal, setOpenChargeOffUploadModal] =
    useState<boolean>(false)
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false)
  const [openChargeOffConfirmationModal, setOpenChargeOffConfirmationModal] =
    useState<boolean>(false)
  const [openMediaUploadModal, setOpenMediaUploadModal] =
    useState<boolean>(false)
  const [totalAccounts, setTotalAccounts] = useState<number>(
    getPersistedAccountData()?.totalCount | 0
  )
  const [accounts, setAccounts] = useState<Account[] | undefined>(
    getPersistedAccountData()?.accounts || []
  )
  const [totalFaceValue, setTotalFaceValue] = useState<number | undefined>(0)
  const [form, setForm] = useState<FormFilter | undefined>(
    getPersistedAccountFilterData() || ''
  )
  const [lastFilterUsed, setLastFilterUsed] = useState<FormFilter | undefined>(
    form
  )
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<number>()

  const methods = useForm({ defaultValues: form })
  useEffect(() => {
    if (!!form?.creditorId) {
      setLoadingData(true)
      fetchAssetTypes()
      fetchPortfolioTemplate()
    }
  }, [form?.creditorId])

  const fetchAssetTypes = (assetTypeId?: string) => {
    assetTypeOperations
      ?.getAssetTypeWithAssociatedTemplateData(form?.creditorId ?? '')
      .then((data: any) => {
        setAssetTypes(data)
        if (assetTypeId) {
          methods.setValue('assetTypeId', assetTypeId)
        }
        setLoadingData(false)
      })
      .catch((error: any) => {
        setLoadingData(false)
      })
    setLoadingData(false)
  }
  const fetchPortfolioTemplate = () => {
    portfolioGetOperations
      ?.getInfoTemplatesData()
      .then((data: any) => {
        setTemplates(data)
        setLoadingData(false)
      })
      .catch((error: any) => {
        setLoadingData(false)
      })
    setLoadingData(false)
  }

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [execQuery, setExecQuery] = useState<boolean>(false)
  const history = useHistory()
  const { userPermissions, isCreditor } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const ability = useContext(AbilityContext)

  const {
    isFetching: fetchingAccounts,
    isLoading: loadingAccounts,
    refetch: refetchAccounts,
  } = useCustomQuery(
    ['getAccountsRestingArea', gridState, execQuery],
    async () =>
      accountOperations
        ?.getAccountsRestingArea(gridState, form)
        .then((data) => {
          setTotalFaceValue(data?.totalFaceValue)
          setAccounts(data.data)
          setTotalAccounts(data.totalCount ?? 0)
          setLoadingData(false)
          setLastFilterUsed(form)
          window.localStorage.setItem(
            'accountsRestingAreaFiltered',
            JSON.stringify({
              accounts: data?.data,
              totalCount: data?.totalCount,
            })
          )
        }),
    { cacheTime: 0, enabled: isInternal ? true : !!form?.creditorId }
  )

  const handleChecked = (props: any) => {
    window.localStorage.setItem(
      'accountsSelected',
      JSON.stringify(accountsSelected)
    )
    return !!accountsSelected.find((x) => x === props.dataItem['ecaid'])
  }
  const cleanItems = () => {
    setFormSendStrategy({})
    setOpenUploadModal(false)
    setFiles([])
    setAccountsSelected([])
    setFormChargeOffBalances({
      addInterestBalancetoPostChargeOffPrincipal: false,
      zeroOutInterestBalanceAtChargeOff: false,
      addFeeBalanceToPostChargeOffPrincipal: false,
      zeroOutFeeBalanceAtChargeOff: false,
    })
  }
  const mutationSendAccountToStrategy = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processSendAccountToStrategy(
        formSendStrategy.creditorId,
        typeUpload === 'allAccounts' ? [] : accountsSelected,
        files[0] ?? null,
        lastFilterUsed,
        formSendStrategy.strategy
      )
    },
    onSuccess: async (response: any) => {
      if (response.data.length > 0) {
        WarningToast(response.data[0].errorMessage)
      } else {
        SuccessToast('Accounts successfully sent')
      }
      refetchAccounts()
      cleanItems()
      setLoadingData(false)
      setOpenUploadModal(false)
      setOpenConfirmationModal(false)
    },
    onError: async (response: any) => {
      setLoadingData(false)
      setOpenUploadModal(false)
      setOpenConfirmationModal(false)
    },
  })

  const mutationChargeOffAccounts = useMutation({
    mutationFn: async () => {
      const chargeOffOptions = {
        chargeOffAccount: true,
        addInterestBalancetoPostChargeOffPrincipal:
          formChargeOffBalances.addInterestBalancetoPostChargeOffPrincipal.toString(),
        zeroOutInterestBalanceAtChargeOff:
          formChargeOffBalances.zeroOutInterestBalanceAtChargeOff.toString(),
        addFeeBalanceToPostChargeOffPrincipal:
          formChargeOffBalances.addFeeBalanceToPostChargeOffPrincipal.toString(),
        zeroOutFeeBalanceAtChargeOff:
          formChargeOffBalances.zeroOutFeeBalanceAtChargeOff.toString(),
      }

      return accountPostOperations.processChargeOffAccounts(
        chargeOffCreditor || form?.creditorId,
        true,
        typeUpload === 'allAccounts' ? [] : accountsSelected,
        files[0] ?? null,
        lastFilterUsed,
        chargeOffOptions
      )
    },
    onSuccess: async (response: any) => {
      if (response.data.length > 0) {
        WarningToast(response.data[0].errorMessage)
      } else {
        SuccessToast('Accounts successfully charged-off')
      }
      refetchAccounts()
      cleanItems()
      setLoadingData(false)
      setOpenChargeOffUploadModal(false)
      setOpenChargeOffConfirmationModal(false)
    },
    onError: async (response: any) => {
      setLoadingData(false)
      setOpenChargeOffUploadModal(false)
      setOpenChargeOffConfirmationModal(false)
    },
  })

  const GridAccountsColumns = () => {
    return [
      {
        title: '#',
        width: 50,
        show: true,
        notFilterable: true,
        render: (props: any) =>
          renderCheckbox(false, handleChecked(props), (checked) => {
            checked
              ? setAccountsSelected([
                  ...accountsSelected,
                  props.dataItem['ecaid'],
                ])
              : setAccountsSelected(
                  accountsSelected.filter(
                    (id) => id !== props.dataItem['ecaid']
                  )
                )
          }),
      },
      {
        field: 'lenderLoanId',
        title: 'Loan ID',
        show: true,
        width: 180,
      },
      {
        field: 'firstName',
        title: 'First Name',
        width: 150,
        show: !isInternal,
      },
      {
        field: 'lastName',
        title: 'Last Name',
        width: 150,
        show: !isInternal,
      },
      { field: 'lender', title: 'Lender', show: true, width: 150 },
      { field: 'lastActivity', title: 'Last Activity', show: true, width: 130 },
      { field: 'accountStatus', title: 'Status', show: true, width: 180 },
      {
        field: 'fundAmount',
        title: 'Orig. Amt.',
        show: true,
        width: 110,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'principalBalance',
        title: 'Principal',
        show: true,
        width: 110,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'interestBalance',
        title: 'Interest',
        show: true,
        width: 100,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'otherFeesBalances',
        title: 'Fee',
        show: true,
        width: 95,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'lastPaymentAmount',
        title: 'Amount',
        show: true,
        width: 100,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'totalBalance',
        title: 'Total Balance',
        show: true,
        width: 135,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'fundDate',
        title: 'Fund Date',
        show: true,
        width: 150,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'defaultDate',
        title: 'Default Date',
        show: true,
        width: 130,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'lastPaymentDate',
        title: 'Last Payment Date',
        show: true,
        width: 165,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'writeOffDate',
        title: 'C/O Date',
        show: true,
        width: 110,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'ecaid',
        title: 'ECAID',
        show: true,
        width: 250,
      },
      {
        field: 'portfolioCreated',
        title: 'Portfolio',
        show: true,
        width: 150,
      },
    ]
  }

  const handleCreditorsUpdate = (creditor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, creditorId: creditor }
    })
  }
  const handleCreditorsUpdateSendStrategy = (creditor: any) => {
    setFormSendStrategy((prevObj: any) => {
      return { ...prevObj, creditorId: creditor }
    })
  }

  const handleCreditorsUpdateChargeOff = (creditor: any) => {
    setChargeOffCreditor(creditor)
  }

  const onDateFromAccountFilterUpdate = (dateAccountFrom: any) => {
    setForm((prevObj: any) => {
      return {
        ...prevObj,
        dateAccountFrom: dateAccountFrom.toISOString(),
        dateFrom: dateAccountFrom.toISOString(),
      }
    })
  }

  const onDateToAccountFilterUpdate = (dateAccountTo: any) => {
    setForm((prevObj: any) => {
      return {
        ...prevObj,
        dateAccountTo: dateAccountTo.toISOString(),
        dateTo: dateAccountTo.toISOString(),
      }
    })
  }
  const [accountsSelected, setAccountsSelected] = useState<string[]>(
    getAccountsSelectedData()
  )
  const getColumns = () => {
    return GridAccountsColumns()
  }

  const handleFilter = () => {
    setAccountsSelected([])
    setPlacerFilter(form?.creditorId)
    window.localStorage.removeItem('accountsSelected')
    if (form)
      window.localStorage.setItem(
        'accountsRestingAreaFilterStorage',
        JSON.stringify(form)
      )
    setGridState({ ...gridState, skip: 0, take: 25 })
    setExecQuery(!execQuery)
  }

  const handleBulkMediaUpload = () => {
    setOpenMediaUploadModal(true)
  }

  const handleRowClick = (event: any) => {
    history.push(getStandardUri(`${ACCOUNT_DETAIL}/${event.dataItem.ecaid}`))
  }
  const handleCloseMultipleSelectionToggle = (event: any) => {
    handleBulkSelectRows(
      event,
      accounts ?? [],
      accountsSelected,
      setAccountsSelected,
      'ecaid'
    )
  }

  const [formSendStrategy, setFormSendStrategy] = useState<any>({
    creditorId: '',
    strategy: '',
  })

  const [chargeOffCreditor, setChargeOffCreditor] = useState<any>()

  const getCreditorsQuery = useQuery({
    queryKey: ['business.getCreditors'],
    queryFn: async () => businessOperations?.getCreditorsData(),
  })

  const getStrategyQuery = useQuery({
    queryKey: ['strategies.getStrategy', formSendStrategy.creditorId],
    queryFn: async () =>
      strategyOperations?.getStrategyByCreditorId(formSendStrategy.creditorId),
    enabled: Boolean(formSendStrategy.creditorId),
  })

  const { isFetching: loadingTemplate } = useCustomQuery(
    ['getTemplateFile', selectedTemplate],
    async () =>
      fileOperations?.getTemplateFile(selectedTemplate).then((template) => {
        setSelectedTemplate(undefined)
        generateFileBlob(template)
      }),
    { cacheTime: 0, enabled: !!selectedTemplate }
  )

  const downloadTemplate = async (templateType: number) => {
    try {
      setSelectedTemplate(templateType)
    } catch (err) {
      console.error(err)
    }
  }

  const getModalTitle = () => {
    return typeUpload === 'filteredAccounts'
      ? 'Charge-Off Selected Accounts Confimation'
      : 'Charge-Off All Accounts Confimation'
  }

  const handleSwitchCheck = (value: any, type: ChargeOffBalanceEnum) => {
    setFormChargeOffBalances((prevObj: any) => {
      return { ...prevObj, [type]: value }
    })

    if (
      type === ChargeOffBalanceEnum.AddInterestBalancetoPostChargeOffPrincipal
    ) {
      setFormChargeOffBalances((prevObj: any) => {
        return {
          ...prevObj,
          [ChargeOffBalanceEnum.ZeroOutInterestBalanceAtChargeOff]: false,
        }
      })
    } else if (
      type === ChargeOffBalanceEnum.ZeroOutInterestBalanceAtChargeOff
    ) {
      setFormChargeOffBalances((prevObj: any) => {
        return {
          ...prevObj,
          [ChargeOffBalanceEnum.AddInterestBalancetoPostChargeOffPrincipal]:
            false,
        }
      })
    } else if (
      type === ChargeOffBalanceEnum.AddFeeBalanceToPostChargeOffPrincipal
    ) {
      setFormChargeOffBalances((prevObj: any) => {
        return {
          ...prevObj,
          [ChargeOffBalanceEnum.ZeroOutFeeBalanceAtChargeOff]: false,
        }
      })
    } else if (type === ChargeOffBalanceEnum.ZeroOutFeeBalanceAtChargeOff) {
      setFormChargeOffBalances((prevObj: any) => {
        return {
          ...prevObj,
          [ChargeOffBalanceEnum.AddFeeBalanceToPostChargeOffPrincipal]: false,
        }
      })
    }
  }

  const strategyList = getStrategyQuery?.data || []
  const canChargeOff =
    ability.can(
      PermissionCodeAccess.Recover_ManuallyChargeOffAccounts,
      'any'
    ) && !isInternal
  return (
    <Page title="Resting Area">
      <Grid container justifyContent="space-between">
        <Box
          display="flex"
          alignItems="flex-end"
          alignContent="flex-end"
          gridGap={10}
        >
          {(isInternal || isCreditor) && (
            <Grid item xs="auto">
              <Placers
                businessOperations={businessOperations}
                onPlacersUpdate={handleCreditorsUpdate}
                selectedPlacer={getPersistedAccountFilterData()?.creditorId}
              />
            </Grid>
          )}
          <Grid item xs="auto">
            <Dropdown
              isLoading={loadingData || fetchingAccounts}
              isFetching={loadingData || fetchingAccounts}
              id="assetTypeId-dropdown"
              placeholder="Asset Type"
              allowEmptyValue
              onChange={(option?: any) => {
                setForm((prevObj: any) => ({
                  ...prevObj,
                  assetTypeId: option?.id,
                }))
              }}
              options={assetTypes?.sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
              )}
              valueOptionName="id"
              labelOptionName="name"
              disabled={!form?.creditorId || loadingData}
            />
          </Grid>
          <Grid item xs="auto">
            <DateAccountFilter
              onDateFromFilterUpdate={onDateFromAccountFilterUpdate}
              onDateToFilterUpdate={onDateToAccountFilterUpdate}
              selectedFromDate={
                getPersistedAccountFilterData()?.dateAccountFrom
              }
              selectedToDate={getPersistedAccountFilterData()?.dateAccountTo}
            >
              <Dropdown
                isLoading={loadingData}
                isFetching={loadingData}
                id="typeDateFilter-dropdown"
                value={form?.dateTypeFilter}
                placeholder="Date type"
                onChange={(option?: any) => {
                  setForm((prevObj: any) => ({
                    ...prevObj,
                    dateTypeFilter: option?.id,
                  }))
                }}
                options={[
                  { id: 1, name: 'Origination Date' },
                  { id: 2, name: 'Fund Date' },
                  { id: 3, name: '1st Delinquency Date' },
                  { id: 4, name: 'Last Delinquency Date' },
                  { id: 5, name: 'Last Payment Date' },
                  { id: 6, name: 'Default Date' },
                  { id: 7, name: 'Charged Off Date' },
                  { id: 8, name: 'Upload Date' },
                ]}
                valueOptionName="id"
                labelOptionName="name"
              />
            </DateAccountFilter>
          </Grid>
          <Grid style={{ paddingLeft: '10px' }} item xs="auto">
            <Switch
              onClick={() =>
                setForm((prevObj: any) => ({
                  ...prevObj,
                  chargeOff: !form?.chargeOff,
                }))
              }
              checked={form?.chargeOff ?? false}
              label={<Typography>Charged Off Accounts</Typography>}
            />
          </Grid>
          \
        </Box>
        <Group>
          <Grid item xs="auto" style={{ paddingBottom: '13px' }}>
            <Button
              useRipple
              width="180px"
              height={40}
              onClick={handleBulkMediaUpload}
              isLoading={loadingData || fetchingAccounts || loadingAccounts}
              disabled={
                (!isInternal && isCreditor && form?.creditorId == null) ||
                loadingTemplate
              }
            >
              Bulk Media Upload
            </Button>
          </Grid>
          <Grid item xs="auto" style={{ paddingBottom: '13px' }}>
            <Button
              useRipple
              width={80}
              height={40}
              onClick={handleFilter}
              isLoading={
                loadingData ||
                fetchingAccounts ||
                loadingAccounts ||
                loadingTemplate
              }
              disabled={
                (!isInternal && isCreditor && form?.creditorId == null) ||
                loadingTemplate
              }
            >
              Filter
            </Button>
          </Grid>
        </Group>
      </Grid>

      <Box mt={5}>
        <Grid item style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Box>
            <ButtonDropdown
              width="190"
              buttontext="Bulk Selection Options"
              id="simple-menu"
              variant="secondary"
              disabled={loadingTemplate}
              mainButtonDropdownChildren={
                <>
                  <ButtonDropdownItem
                    onClick={() =>
                      handleCloseMultipleSelectionToggle(
                        'Select all rows (current page)'
                      )
                    }
                  >
                    Select all rows (current page)
                  </ButtonDropdownItem>
                  <ButtonDropdownItem
                    onClick={() =>
                      handleCloseMultipleSelectionToggle(
                        'Unselect all rows (current page)'
                      )
                    }
                  >
                    Unselect all rows (current page)
                  </ButtonDropdownItem>

                  <ButtonDropdownItem
                    onClick={() =>
                      handleCloseMultipleSelectionToggle(
                        'Unselect all rows (all pages)'
                      )
                    }
                  >
                    Unselect all rows (all pages)
                  </ButtonDropdownItem>
                </>
              }
              enableSideButton={false}
              doubleDropDown={true}
            ></ButtonDropdown>
          </Box>
          <Box>
            <Typography style={{ marginBottom: 0 }}>
              Total of accounts selected: {accountsSelected.length}
            </Typography>
          </Box>
          <Box>
            <ButtonDropdown
              buttontext="Send to Strategy"
              width="180px"
              variant="primary"
              disabled={loadingTemplate}
              mainButtonDropdownChildren={
                <>
                  <ButtonDropdownItem
                    onClick={() => {
                      setOpenConfirmationModal(true)
                      setTypeUpload('filteredAccounts')
                    }}
                    disabled={!accountsSelected.length}
                  >
                    Send selected accounts
                  </ButtonDropdownItem>
                  <ButtonDropdownItem
                    disabled={!accounts?.length}
                    onClick={() => {
                      setOpenConfirmationModal(true)
                      setTypeUpload('allAccounts')
                    }}
                  >
                    Send all accounts
                  </ButtonDropdownItem>
                  <ButtonDropdownItem onClick={() => setOpenUploadModal(true)}>
                    Upload file
                  </ButtonDropdownItem>
                  <ButtonDropdownItem
                    onClick={() =>
                      downloadTemplate(FileTypeEnum.SendToStrategy)
                    }
                  >
                    Download Template
                  </ButtonDropdownItem>
                </>
              }
              enableSideButton={false}
              doubleDropDown={true}
            ></ButtonDropdown>
          </Box>
          {canChargeOff && (
            <Box>
              <ButtonDropdown
                buttontext="Charge-Off Accounts"
                width="210px"
                variant="primary"
                doubleDropDown={true}
                disabled={loadingTemplate}
                mainButtonDropdownChildren={
                  <>
                    <ButtonDropdownItem
                      disabled={!accountsSelected.length}
                      onClick={() => {
                        setOpenChargeOffConfirmationModal(true)
                        setTypeUpload('filteredAccounts')
                      }}
                    >
                      Charge-Off selected accounts
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      disabled={!accounts?.length}
                      onClick={() => {
                        setOpenChargeOffConfirmationModal(true)
                        setTypeUpload('allAccounts')
                      }}
                    >
                      Charge-Off all accounts
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      onClick={() => setOpenChargeOffUploadModal(true)}
                    >
                      Upload accounts file
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      onClick={() =>
                        downloadTemplate(FileTypeEnum.ChargeOffAccounts)
                      }
                    >
                      Download Template
                    </ButtonDropdownItem>
                  </>
                }
                enableSideButton={false}
              ></ButtonDropdown>
            </Box>
          )}
          {!isInternal && (
            <Box>
              <ButtonDropdown
                buttontext="Sell Accounts"
                width="180px"
                variant="primary"
                disabled={loadingTemplate}
                mainButtonDropdownChildren={
                  <>
                    <ButtonDropdownItem
                      onClick={() => {
                        setOpenSellModal(true)
                        setTypeUpload('filteredAccounts')
                      }}
                      disabled={!accountsSelected.length}
                    >
                      Sell selected accounts
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      disabled={!accounts?.length}
                      onClick={() => {
                        setTypeUpload('allAccounts')
                        setOpenSellModal(true)
                      }}
                    >
                      Sell all accounts
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      onClick={() => {
                        setTypeUpload('file')
                        setOpenSellModal(true)
                      }}
                    >
                      Upload file
                    </ButtonDropdownItem>
                  </>
                }
                enableSideButton={false}
                doubleDropDown={true}
              ></ButtonDropdown>
            </Box>
          )}
        </Grid>
      </Box>
      <ModalDialog
        header="Confirmation"
        isOpen={openConfirmationModal}
        onClose={() => {
          setOpenConfirmationModal(false)
          setFormSendStrategy({})
          setFiles([])
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={
          !formSendStrategy.creditorId ||
          !formSendStrategy.strategy ||
          loadingData ||
          fetchingAccounts
        }
        onContinue={() => {
          setLoadingData(true)
          mutationSendAccountToStrategy.mutate(null)
        }}
        maxwidth="550px"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Step
              completed={!!formSendStrategy?.creditorId}
              title="Select the business"
              stepNumber="1"
            >
              <Placers
                businessOperations={businessOperations}
                onPlacersUpdate={handleCreditorsUpdateSendStrategy}
                selectedPlacer={formSendStrategy?.creditorId}
              />
            </Step>
          </Grid>
          <Grid item xs={12}>
            <Step
              completed={formSendStrategy?.strategy}
              title="Select the strategy"
              stepNumber="2"
            >
              <Dropdown
                onChange={(props: any) =>
                  setFormSendStrategy((prevObj: any) => ({
                    ...prevObj,
                    strategy: props?.id,
                  }))
                }
                isLoading={getStrategyQuery.isFetching}
                width="100%"
                disabled={
                  !formSendStrategy?.creditorId || getCreditorsQuery.isFetching
                }
                options={strategyList}
                placeholder="Strategy"
                value={formSendStrategy?.strategy}
                valueOptionName="id"
                labelOptionName="name"
              ></Dropdown>
            </Step>
          </Grid>

          <Grid item xs={12}>
            <Typography
              color={Colors.primary}
              variant="caption"
              style={{ fontSize: 18 }}
            >
              {`Do you confirm that you want to send 
                ${
                  typeUpload === 'filteredAccounts'
                    ? accountsSelected.length
                    : totalAccounts
                }
                 Accounts?`}
            </Typography>
          </Grid>
        </Grid>
      </ModalDialog>

      <ModalDialog
        header="Confirmation"
        isOpen={openUploadModal}
        onClose={() => {
          setOpenUploadModal(false)
          setFormSendStrategy({})
          setFiles([])
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={
          !files.length ||
          !formSendStrategy.creditorId ||
          !formSendStrategy.strategy ||
          loadingData ||
          fetchingAccounts
        }
        onContinue={() => {
          setLoadingData(true)
          mutationSendAccountToStrategy.mutate(null)
        }}
        maxwidth="550px"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Step
              completed={!!formSendStrategy?.creditorId}
              title="Select the business"
              stepNumber="1"
            >
              <Creditors
                businessOperations={businessOperations}
                onCreditorsUpdate={handleCreditorsUpdateSendStrategy}
                selectedCreditor={formSendStrategy?.creditorId}
              />
            </Step>
          </Grid>
          <Grid item xs={12}>
            <Step
              completed={formSendStrategy?.strategy}
              title="Select the strategy"
              stepNumber="2"
            >
              <Dropdown
                onChange={(props: any) =>
                  setFormSendStrategy((prevObj: any) => ({
                    ...prevObj,
                    strategy: props?.id,
                  }))
                }
                isLoading={getStrategyQuery.isFetching}
                width="100%"
                disabled={
                  !formSendStrategy?.creditorId || getCreditorsQuery.isFetching
                }
                options={strategyList}
                placeholder="Strategy"
                value={formSendStrategy?.strategy}
                valueOptionName="id"
                labelOptionName="name"
              />
            </Step>
          </Grid>

          <Grid item xs={12}>
            <Step
              completed={!!files.length}
              title="Select the file"
              stepNumber="2"
            >
              <Grid>
                <UploadDragDrop
                  files={files}
                  setFiles={setFiles}
                  hideUploadButton={true}
                />
              </Grid>
            </Step>
          </Grid>
        </Grid>
      </ModalDialog>

      <ModalDialog
        header={getModalTitle()}
        isOpen={openChargeOffConfirmationModal}
        onClose={() => {
          setOpenChargeOffConfirmationModal(false)
          setFiles([])
          setChargeOffCreditor(null)
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={!form?.creditorId || loadingData || fetchingAccounts}
        onContinue={() => {
          setLoadingData(true)
          mutationChargeOffAccounts.mutate()
        }}
        isFetching={mutationChargeOffAccounts.isLoading}
        isLoading={mutationChargeOffAccounts.isLoading}
        maxwidth="650px"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Step
              completed={true}
              title="Select Charge-Off balance adjustments"
              stepNumber="1"
            >
              <Box style={{ padding: 10 }}>
                <Typography style={{ padding: 5 }}>
                  Add Interest Balance to Post-Charge-Off Principal?
                </Typography>
                <SwitchType
                  id="AddInterestBalancetoPostChargeOffPrincipal"
                  checked={
                    formChargeOffBalances.addInterestBalancetoPostChargeOffPrincipal
                  }
                  onChange={(e: { target: { checked: any } }) =>
                    handleSwitchCheck(
                      e.target.checked,
                      ChargeOffBalanceEnum.AddInterestBalancetoPostChargeOffPrincipal
                    )
                  }
                  variant="primary"
                  primary="No"
                  secondary="Yes"
                />
              </Box>
              <Box style={{ padding: 10 }}>
                <Typography style={{ padding: 5 }}>
                  Zero out Interest Balance at Charge-Off?
                </Typography>
                <SwitchType
                  id="ZeroOutInterestBalanceAtChargeOff"
                  checked={
                    formChargeOffBalances.zeroOutInterestBalanceAtChargeOff
                  }
                  onChange={(e: { target: { checked: any } }) =>
                    handleSwitchCheck(
                      e.target.checked,
                      ChargeOffBalanceEnum.ZeroOutInterestBalanceAtChargeOff
                    )
                  }
                  variant="primary"
                  primary="No"
                  secondary="Yes"
                />
              </Box>
              <Box style={{ padding: 10 }}>
                <Typography style={{ padding: 5 }}>
                  Add Fee Balance to Post-Charge-Off Principal?
                </Typography>
                <SwitchType
                  id="AddFeeBalanceToPostChargeOffPrincipal"
                  checked={
                    formChargeOffBalances.addFeeBalanceToPostChargeOffPrincipal
                  }
                  onChange={(e: { target: { checked: any } }) =>
                    handleSwitchCheck(
                      e.target.checked,
                      ChargeOffBalanceEnum.AddFeeBalanceToPostChargeOffPrincipal
                    )
                  }
                  variant="primary"
                  primary="No"
                  secondary="Yes"
                />
              </Box>
              <Box style={{ padding: 10 }}>
                <Typography style={{ padding: 5 }}>
                  Zero out Fee Balance at Charge-Off?
                </Typography>
                <SwitchType
                  id="ZeroOutFeeBalanceAtChargeOff"
                  checked={formChargeOffBalances.zeroOutFeeBalanceAtChargeOff}
                  onChange={(e: { target: { checked: any } }) =>
                    handleSwitchCheck(
                      e.target.checked,
                      ChargeOffBalanceEnum.ZeroOutFeeBalanceAtChargeOff
                    )
                  }
                  variant="primary"
                  primary="No"
                  secondary="Yes"
                />
              </Box>
            </Step>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color={Colors.primary}
              variant="caption"
              style={{ fontSize: 18 }}
            >
              {typeUpload === 'filteredAccounts'
                ? `Do you confirm that you want to Charge-Off ${accountsSelected.length} Accounts?`
                : `Do you confirm that you want to Charge-Off ${totalAccounts} Accounts?`}
            </Typography>
          </Grid>
        </Grid>
      </ModalDialog>
      <ModalDialog
        header="Upload Accounts File Confirmation"
        isOpen={openChargeOffUploadModal}
        onClose={() => {
          setIsLoading(false)
          setOpenChargeOffUploadModal(false)
          setFiles([])
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={
          !files.length || !chargeOffCreditor || loadingData || fetchingAccounts
        }
        onContinue={() => {
          setLoadingData(true)
          if (files[0]) {
            setIsLoading(true)
            fParseXlsxFile(files[0]).then((result: any) => {
              history.push({
                pathname: getStandardUri(
                  CHARGE_OFF_RESTING_ACCOUNT_FILE_VALIDATION
                ),
                state: {
                  creditorId: chargeOffCreditor,
                  fileData: result.data,
                  fileUploaded: files[0],
                },
              })
            })
          }
        }}
        isLoading={isLoading}
        isFetching={isLoading}
        maxwidth="550px"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Step
              completed={!!chargeOffCreditor}
              title="Select the business"
              stepNumber="1"
            >
              <Creditors
                businessOperations={businessOperations}
                onCreditorsUpdate={handleCreditorsUpdateChargeOff}
                selectedCreditor={chargeOffCreditor}
              />
            </Step>
          </Grid>
          <Grid item xs={12}>
            <Step
              completed={!!files.length}
              title="Select the file"
              stepNumber="2"
            >
              <Grid>
                <UploadDragDrop
                  files={files}
                  setFiles={setFiles}
                  hideUploadButton={true}
                />
              </Grid>
            </Step>
          </Grid>
        </Grid>
      </ModalDialog>
      <UploadModal
        open={openMediaUploadModal}
        setOpenUploadModal={setOpenMediaUploadModal}
      />
      <SellAccountsModal
        filterAccounts={lastFilterUsed}
        open={openSellModal}
        setIsOpen={setOpenSellModal}
        accountPostOperations={accountPostOperations}
        assetTypes={assetTypes}
        businessOperations={businessOperations}
        loadingData={loadingData}
        templates={templates}
        cleanItems={cleanItems}
        refetchAccounts={refetchAccounts}
        typeUpload={typeUpload!}
        selectedECAIDs={accountsSelected}
      />
      {totalFaceValue && totalFaceValue > 0 && (
        <Box mt={5}>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Box marginRight={2}>
              <Typography>
                Total face value: {fNumberCurrency(totalFaceValue)}
              </Typography>
            </Box>
          </Grid>
        </Box>
      )}
      <Box mt={3}>
        <DataTable
          sortable={true}
          useFilterMenu={true}
          isLoading={loadingData || fetchingAccounts || loadingAccounts}
          height="100%"
          maxHeight="100%"
          gridColumns={getColumns() || []}
          gridState={gridState}
          data={accounts}
          pageable={true}
          total={totalAccounts}
          onRowClick={handleRowClick}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
          }}
        />
      </Box>
    </Page>
  )
}

export default RestingArea
