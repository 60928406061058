import React from 'react'
import { Content, Grid, Header } from 'everchain-uilibrary'

interface PageProps {
  children: React.ReactNode
  title: string
  subtitle?: string
  customBackUrl?: string
  id?: string
  actions?: React.ReactNode
}
const Page: React.FC<PageProps> = ({
  children,
  title,
  subtitle,
  customBackUrl,
  id,
  actions,
}) => {
  return (
    <>
      <Content id={id} width="100%">
        <Grid item xs={12}>
          <Header
            title={title}
            subtitle={subtitle}
            customBackUrl={customBackUrl}
            style={{ paddingBottom: '10px' }}
            actions={actions}
          />
        </Grid>
        {children}
      </Content>
    </>
  )
}
export default Page
