import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IAssetTypesGetOperations } from 'src/domain/features/get/assetTypes/assetTypes'
import { AssetType } from 'src/domain/models/assetTypes/assetTypes'
import {
  AssetTypeGetAssetTypeByBusiness,
  AssetTypeGetAssetTypeWithAssociatedTemplateByBusiness,
} from './urls'

export class AssetTypesData implements IAssetTypesGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getAssetTypeData(businessId: string): Promise<AssetType[]> {
    const response = await this.httpClient.get(
      `${AssetTypeGetAssetTypeByBusiness}?businessId=${businessId}`
    )
    return response?.data
  }

  async getAssetTypeWithAssociatedTemplateData(
    businessId: string
  ): Promise<AssetType[]> {
    const response = await this.httpClient.get(
      `${AssetTypeGetAssetTypeWithAssociatedTemplateByBusiness}?businessId=${businessId}`
    )
    return response?.data
  }
}
