import React from 'react'
import {
  Accordion,
  Divider,
  fFormatDateTime,
  Grid,
  Icon,
  Typography,
} from 'everchain-uilibrary'

interface PostPlacementDetailsParams {
  initiatedByAgency: boolean | undefined
  requestedOn: Date
  requestedBy: string | undefined
  rejectedBy?: string | null
  rejectedOn?: Date | null
  rejectedComment?: string | null
  isLoading: boolean
}

const PostPlacementDetails: React.FC<PostPlacementDetailsParams> = ({
  initiatedByAgency,
  requestedOn,
  requestedBy,
  rejectedBy,
  rejectedOn,
  rejectedComment,
  isLoading,
}) => {
  return (
    <Accordion
      icon={<Icon fontSize="17px" name="Info" />}
      title="Request Details"
      defaultOpen={true}
      isLoading={isLoading}
    >
      <Grid container>
        <Grid item xs={6} sm={6}>
          <Typography>Initiated by agency:</Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography>{initiatedByAgency ? 'Yes' : 'No'}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider orientation="horizontal" />
        </Grid>
        <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
          <Typography>Requested on:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
          <Typography>{fFormatDateTime(requestedOn)}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider orientation="horizontal" />
        </Grid>
        <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
          <Typography>Requested by:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
          <Typography>{requestedBy}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider orientation="horizontal" />
        </Grid>
        {rejectedOn && (
          <>
            <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
              <Typography>Rejected on:</Typography>
            </Grid>
            <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
              <Typography>{fFormatDateTime(rejectedOn)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider orientation="horizontal" />
            </Grid>
          </>
        )}
        {rejectedBy && (
          <>
            <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
              <Typography>Rejected By:</Typography>
            </Grid>
            <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
              <Typography>{rejectedBy}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider orientation="horizontal" />
            </Grid>
          </>
        )}
        {rejectedComment && (
          <>
            <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
              <Typography>Rejected Comment:</Typography>
            </Grid>
            <Grid item xs={6} sm={6} style={{ marginTop: 3 }}>
              <Typography>{rejectedComment}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider orientation="horizontal" />
            </Grid>
          </>
        )}
      </Grid>
    </Accordion>
  )
}

export default PostPlacementDetails
