import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { TemplateFile } from 'src/domain/models/mediaUpload'
import {
  MediaDownloadDeleteMediaFile,
  MediaDownloadGetAccountMediaFiles,
  MediaDownloadGetAllAccountMediaFile,
  MediaDownloadGetMediaFile,
  MediaDownloadGetTemplateFile,
} from './urls'
import { IMediaDownloadGetOperations } from 'src/domain/features/get/mediaDownload/mediaDownload'
import { MediaFileDetailsData } from 'src/domain/models/mediaFiles'
import { FileUri } from 'src/domain/models/file'

export class MediaDownloadGetData implements IMediaDownloadGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getAllAccountMedia(
    creditorId: any,
    vendorId: any,
    dateFrom: any,
    dateTo: any
  ): Promise<TemplateFile> {
    const creditorFilter = creditorId ? `creditorId= ${creditorId} ` : ''
    const vendorFilter = vendorId ? `&vendorId= ${vendorId} ` : ''
    const dateFromFilter = dateFrom ? `&dateFrom= ${dateFrom} ` : ''
    const dateToFilter = dateTo ? `&dateTo= ${dateTo} ` : ''
    const response = await this.httpClient.get(
      `${MediaDownloadGetAllAccountMediaFile}?${vendorFilter}${creditorFilter}${dateFromFilter}${dateToFilter}`
    )
    return response?.data
  }

  async getDownloadMediaTemplateFile(
    creditorId: any,
    vendorId: any,
    dateFrom: any,
    dateTo: any
  ): Promise<TemplateFile> {
    const creditorFilter = creditorId ? `creditorId= ${creditorId} ` : ''
    const vendorFilter = vendorId ? `&vendorId= ${vendorId} ` : ''
    const dateFromFilter = dateFrom ? `&dateFrom= ${dateFrom} ` : ''
    const dateToFilter = dateTo ? `&dateTo= ${dateTo} ` : ''
    const response = await this.httpClient.get(
      `${MediaDownloadGetTemplateFile}?${vendorFilter}${creditorFilter}${dateFromFilter}${dateToFilter}`
    )
    return response?.data
  }

  async getAllMediaFileFromAccount(
    ecaid: any,
    lenderLoanId: any
  ): Promise<MediaFileDetailsData[]> {
    const ecaidFilter = ecaid ? `ecaId= ${ecaid} ` : ''
    const lenderLoanIdFilter = lenderLoanId
      ? `lenderLoanId= ${lenderLoanId} `
      : ''
    const response = await this.httpClient.get(
      `${MediaDownloadGetAccountMediaFiles}?${ecaidFilter}&${lenderLoanIdFilter}`
    )
    return response?.data
  }

  async getMediaFile(mediaFileId: any): Promise<FileUri> {
    const mediaFileIdFilter = mediaFileId ? `mediaFileId= ${mediaFileId} ` : ''
    const response = await this.httpClient.get(
      `${MediaDownloadGetMediaFile}?${mediaFileIdFilter}`
    )
    return response?.data
  }

  async deleteMediaFile(mediaFileId: any): Promise<Boolean> {
    const mediaFileIdFilter = mediaFileId ? `mediaFileId= ${mediaFileId} ` : ''
    const response = await this.httpClient.get(
      `${MediaDownloadDeleteMediaFile}?${mediaFileIdFilter}`
    )
    return response?.data
  }
}
