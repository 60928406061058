import React from 'react'
import { Accordion, Icon } from 'everchain-uilibrary'
import AuditInfo, { AuditInfoProps } from './AuditInfo'

const AuditInfoAccordion: React.FC<AuditInfoProps> = ({
  ...rest
}: AuditInfoProps) => {
  return (
    <Accordion title="Audit Info" icon={<Icon name="VerifiedUser" />}>
      <AuditInfo {...rest} />
    </Accordion>
  )
}

export default AuditInfoAccordion
