import { BACKEND_URL, ACCOUNT_URL } from 'src/infra/http/httpClient'

const postPlacementPrefix = 'postPlacement.'
const accountUtilsPrefix = 'utils.'

export const PostPlacementGetAccounts = `${BACKEND_URL}${postPlacementPrefix}getAccounts`
export const PostPlacementGetTemplate = `${ACCOUNT_URL}${accountUtilsPrefix}getTemplateFile`
export const PostPlacementGetPostPlacementDetails = `${ACCOUNT_URL}${postPlacementPrefix}getPostPlacementDetails`
export const PostPlacementGetPostPlacementCards = `${ACCOUNT_URL}${postPlacementPrefix}getPostPlacementCards`
export const PostPlacementGetPostPlacementCardItems = `${ACCOUNT_URL}${postPlacementPrefix}getPostPlacementCardItems`
export const PostPlacementGetPostPlacementComments = `${ACCOUNT_URL}${postPlacementPrefix}getPostPlacementComments`
export const PostPlacementGetPostPlacementRequests = `${ACCOUNT_URL}${postPlacementPrefix}getPostPlacementRequests`
