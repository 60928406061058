import React, { useState } from 'react'
import {
  Accordion,
  Box,
  Button,
  DataTable,
  ErrorToast,
  fFormatDate,
  Grid,
  Icon,
  Skeleton,
  SuccessToast,
  TextArea,
  Typography,
} from 'everchain-uilibrary'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { useCustomQuery } from 'src/infra/reactQuery'
import { PostPlacementCommentData } from 'src/domain/models/post-placement'
import { useMutation } from '@tanstack/react-query'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'

interface PostPlacementCommentParams {
  postPlacementOperations: IPostPlacementGetOperations
  postPlacementPostOperations: IPostPlacementPostOperations
  requestId: number
}
const PostPlacementComment: React.FC<PostPlacementCommentParams> = ({
  postPlacementOperations,
  postPlacementPostOperations,
  requestId,
}) => {
  const [comment, setComment] = useState<string>('')

  const {
    data: postPlacementComments,
    isLoading: isLoadingComments,
    refetch,
  } = useCustomQuery<PostPlacementCommentData[]>(
    ['getPostPlacementComments', requestId],
    async () => postPlacementOperations?.getPostPlacementComments(requestId),
    { cacheTime: 0 }
  )

  const mutationAddComment = useMutation({
    mutationFn: async () => {
      return postPlacementPostOperations?.addComment(requestId, comment)
    },
    onSuccess: async (response: any) => {
      SuccessToast('Comment added')
      setComment('')
      refetch()
    },
    onError: async (response: any) => {
      ErrorToast('Error adding comment')
    },
  })

  const gridColumns = [
    {
      field: 'commentItem',
      title: 'Comments',
      show: true,
      render: (props: any) => (
        <td>
          <Grid style={{ padding: 0 }}>
            <Grid item style={{ padding: 0 }}>
              <Typography variant="subtitle2" style={{ margin: 0 }}>
                {props.dataItem.userName}
              </Typography>
            </Grid>
            <Grid item style={{ padding: 0 }}>
              {props.dataItem.comment}
            </Grid>
            <Grid item style={{ padding: 0 }}>
              <Typography>{fFormatDate(props.dataItem.createdAt)}</Typography>
            </Grid>
          </Grid>
        </td>
      ),
    },
  ]

  return (
    <Accordion
      icon={<Icon fontSize="17px" name="Comment" />}
      title="Comments"
      defaultOpen={true}
      isLoading={isLoadingComments}
    >
      <TextArea
        onChange={(event: any) => {
          setComment(event.currentTarget.value)
        }}
        placeholder="Enter a new comment"
        variant="primary"
        width={'100%'}
        value={comment}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: -15 }}
      >
        <Button
          leftIcon={<Icon fontSize="small" name="Add" />}
          onClick={() => mutationAddComment.mutate()}
          disabled={isLoadingComments || comment.length < 1}
          isFetching={mutationAddComment.isLoading}
        >
          Add Comment
        </Button>
      </Box>
      {postPlacementComments && (
        <Skeleton width="100%" height={50} isLoading={isLoadingComments}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ padding: 0, marginTop: 10 }}
          >
            <DataTable data={postPlacementComments} gridColumns={gridColumns} />
          </Grid>
        </Skeleton>
      )}
    </Accordion>
  )
}

export default PostPlacementComment
