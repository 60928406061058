import React, { useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import AccountDetailFeature from './components/AccountDetailFeature'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { useCustomQuery } from 'src/infra/reactQuery'
import { ACCOUNTS } from 'src/presentation/routes'
import { AccountDetailsData } from 'src/domain/models/accounts'
import { AuthContext } from 'src/context/AuthenticationContext'

import { useGetBusinessessId } from 'src/utils/user'
import { ITransactionPostOperations } from 'src/domain/features/post/transaction/transaction'
import { getStandardUri } from 'src/utils/common'
import { MediaFileDetailsData } from 'src/domain/models/mediaFiles'
import { IMediaDownloadGetOperations } from 'src/domain/features/get/mediaDownload/mediaDownload'
import Page from '../../Common/Page'

interface AccountDetailsProps {
  accountOperations: IAccountGetOperations
  transactionPostOperation: ITransactionPostOperations
  mediaFilesOperation: IMediaDownloadGetOperations
}

const AccountDetails: React.FC<AccountDetailsProps> = ({
  accountOperations,
  transactionPostOperation,
  mediaFilesOperation,
}: AccountDetailsProps) => {
  const { ECAID, backUrl } = useParams<any>()
  const [account, setAccount] = useState<AccountDetailsData>()
  const history = useHistory()
  const { userPermissions, isCreditor } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const businessIds = useGetBusinessessId()

  const {
    data: accountDetailsData,
    isError: isDetailsError,
    isFetching: isDetailsLoading,
  } = useCustomQuery<AccountDetailsData>(
    ['getAccountDetails'],
    async () => accountOperations?.getAccountDetails(ECAID, businessIds),
    { cacheTime: 0 }
  )

  const {
    data: mediaFilesData,
    isFetching: idMediaFilesLoading,
    refetch: getMediaFilesDetailsRefetch,
  } = useCustomQuery<MediaFileDetailsData[]>(
    ['getMediaFilesDetails'],
    async () =>
      mediaFilesOperation.getAllMediaFileFromAccount(
        ECAID,
        account?.lenderLoanId
      ),
    { cacheTime: 0 }
  )

  const createCardTitle = () => {
    if (!account) return ''

    if (isInternal) return account?.lenderLoanId

    return `${account?.lastName}, ${account?.firstName?.charAt(0)} | ${
      account?.lenderLoanId
    }`
  }

  useMemo(() => {
    if (isDetailsError) {
      history.push(getStandardUri(ACCOUNTS))
    }
    if (!isDetailsLoading) {
      setAccount(accountDetailsData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetailsLoading, isDetailsError])

  return (
    <Page title={createCardTitle()} customBackUrl={getStandardUri(backUrl)}>
      <AccountDetailFeature
        account={account}
        eCAID={ECAID}
        accountOperations={accountOperations}
        isLoading={isDetailsLoading}
        isCreditor={isCreditor}
        isInternal={isInternal}
        transactionPostOperation={transactionPostOperation}
        mediaFilesData={mediaFilesData}
        isMediaFilesLoading={idMediaFilesLoading}
        mediaFilesRefetch={getMediaFilesDetailsRefetch}
      />
    </Page>
  )
}
export default AccountDetails
