import { DatePicker, Dropdown, Grid } from 'everchain-uilibrary'
import React from 'react'
import { PortfolioTemplateInfo } from 'src/domain/models/portfolio/portfolio'

interface SellAccountsNonSplitSubmitStepProps {
  templateId: number | undefined
  setTemplateId: (value: number | undefined) => void
  templates?: PortfolioTemplateInfo[]
  cutOffDate: Date | null
  setCutOffDate: (value: Date | null) => void
}

const SellAccountsNonSplitSubmitStep: React.FC<
  SellAccountsNonSplitSubmitStepProps
> = ({
  templateId,
  setTemplateId,
  templates,
  cutOffDate,
  setCutOffDate,
}: SellAccountsNonSplitSubmitStepProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Dropdown
          id="sell-accounts-template-id"
          placeholder="Template"
          width="100%"
          onChange={(op: any) => setTemplateId(op.templateId)}
          value={templateId}
          options={templates ?? []}
          allowEmptyValue={false}
          disabled={!templates}
          valueOptionName="templateId"
          labelOptionName="templateName"
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          label="Cut-Off Date"
          value={cutOffDate}
          onChange={setCutOffDate}
          width="100%"
        />
      </Grid>
    </Grid>
  )
}

export default SellAccountsNonSplitSubmitStep
