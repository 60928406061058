import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { DataResponse } from 'src/domain/models/common'
import { DataTableState } from 'everchain-uilibrary'
import { IAuditGetOperations } from 'src/domain/features/get/audit/audit'

import * as types from 'src/domain/models/audit'
import * as urls from './urls'

export class AuditGetData implements IAuditGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getByGroup(
    groupKey: number,
    auditGroup: string,
    pagination: DataTableState
  ): Promise<DataResponse<types.AuditResponse>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const response = await this.httpClient.get(
      `${urls.AuditsGetByGroup}?groupKey=${groupKey}&auditGroup=${auditGroup}&pagination=${encodedData}`
    )
    return response.data
  }
}
