import React, { useContext, useState } from 'react'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import {
  Box,
  Button,
  Card,
  DataTable,
  DataTableState,
  Grid,
  renderDate,
  Skeleton,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/reactQuery'
import {
  PostPlacementCardsData,
  PostPlacementListData,
} from 'src/domain/models/post-placement'
import { useHistory, useParams } from 'react-router-dom'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getStandardUri } from 'src/utils/common'
import { BULK_POST_PLACEMENT, POST_PLACEMENT } from 'src/presentation/routes'
import Page from '../Common/Page'

interface PostPlacementParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations?: IPostPlacementGetOperations
  postPlacementPostOperations?: IPostPlacementPostOperations
}

const PostPlacementManager: React.FC<PostPlacementParams> = ({
  postPlacementOperations,
}) => {
  const { backUrl } = useParams<any>()
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [group, setGroup] = useState<string>('urgent')
  const [totalRequests, setTotalRequests] = useState<number>(0)
  const [requestsData, setRequestsData] = useState<
    PostPlacementListData[] | undefined
  >()

  const history = useHistory()

  const defaultPagination = {
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  }

  const [gridState, setGridState] = useState<DataTableState>(defaultPagination)

  const { data: postPlacementData, isLoading: isLoadingPostPlacement } =
    useCustomQuery<PostPlacementCardsData>(
      ['getPostPlacementCards'],
      async () => postPlacementOperations?.getPostPlacementCards(),
      { cacheTime: 0 }
    )

  const { isLoading: isLoadingPostPlacementItems } = useCustomQuery<any>(
    ['getPostPlacementCardItems', group, gridState],
    async () =>
      postPlacementOperations
        ?.getPostPlacementCardItems(group, gridState)
        .then((data) => {
          setTotalRequests(data.totalCount ?? 0)
          setRequestsData(data.data)
        }),
    { cacheTime: 0 }
  )

  const handleCreateNewRequestButton = (search: any) => {
    history.push(getStandardUri(`${BULK_POST_PLACEMENT}`))
  }

  const getColumns = () => {
    return [
      {
        field: 'requestId',
        title: 'ID',
        show: true,
        width: 100,
        filter: 'numeric',
      },
      {
        field: 'creditor',
        title: 'Placer',
        show: true,
        width: 170,
        notFilterable: true,
      },
      {
        field: 'lender',
        title: 'Lender',
        show: true,
        width: 170,
      },
      {
        field: 'type',
        title: 'Type',
        show: true,
        width: 170,
      },
      {
        field: 'status',
        title: 'Status',
        show: true,
        width: 170,
      },
      {
        field: 'loanId',
        title: 'Loan ID',
        show: true,
        width: 170,
      },
      {
        field: 'agency',
        title: 'Agency',
        width: 170,
        show: true,
        notFilterable: true,
      },
      {
        field: 'lastUpdatedAt',
        title: 'Last Updated',
        show: true,
        width: 150,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'assignedToCreditor',
        title: 'Assigned To',
        show: true,
        width: 130,
      },
    ]
  }

  const handleCardClick = (newGroup: string) => {
    setGroup(newGroup)
    setGridState(defaultPagination)
  }

  const handleRowClick = (event: any) => {
    history.push(
      getStandardUri(`${POST_PLACEMENT}/${event.dataItem.requestId}`)
    )
  }

  return (
    <Page
      title="Post Placement Manager"
      customBackUrl={getStandardUri(backUrl)}
    >
      <Box data-test-id="account-details">
        <Skeleton isLoading={isLoadingPostPlacement} width="100%" height="50px">
          <Grid container spacing={5}>
            <Grid item xl={3} sm={3}>
              <Card
                content={postPlacementData?.urgent.total}
                warning={
                  postPlacementData?.urgent.warning
                    ? postPlacementData?.urgent.warning
                    : ''
                }
                error={
                  postPlacementData?.urgent.attention
                    ? postPlacementData?.urgent.attention
                    : ''
                }
                success={
                  postPlacementData?.urgent.normal
                    ? postPlacementData?.urgent.normal
                    : ''
                }
                footer="Urgent"
                onClick={() => handleCardClick('urgent')}
                variant={group === 'urgent' ? 'primary' : 'secondary'}
              />
            </Grid>
            <Grid item xl={3} sm={3}>
              <Card
                content={postPlacementData?.info.total}
                warning={
                  postPlacementData?.info.warning
                    ? postPlacementData?.info.warning
                    : ''
                }
                error={
                  postPlacementData?.info.attention
                    ? postPlacementData?.info.attention
                    : ''
                }
                success={
                  postPlacementData?.info.normal
                    ? postPlacementData?.info.normal
                    : ''
                }
                footer="Information Requests"
                onClick={() => handleCardClick('info')}
                variant={group === 'info' ? 'primary' : 'secondary'}
              />
            </Grid>
            <Grid item xl={3} sm={3}>
              <Card
                content={postPlacementData?.recallReturn.total}
                warning={
                  postPlacementData?.recallReturn.warning
                    ? postPlacementData?.recallReturn.warning
                    : ''
                }
                error={
                  postPlacementData?.recallReturn.attention
                    ? postPlacementData?.recallReturn.attention
                    : ''
                }
                success={
                  postPlacementData?.recallReturn.normal
                    ? postPlacementData?.recallReturn.normal
                    : ''
                }
                footer="Recall/Return"
                onClick={() => handleCardClick('recallReturn')}
                variant={group === 'recallReturn' ? 'primary' : 'secondary'}
              />
            </Grid>
            <Grid item xl={3} sm={3}>
              <Card
                content={postPlacementData?.retention.total}
                warning={
                  postPlacementData?.retention.warning
                    ? postPlacementData?.retention.warning
                    : ''
                }
                error={
                  postPlacementData?.retention.attention
                    ? postPlacementData?.retention.attention
                    : ''
                }
                success={
                  postPlacementData?.retention.normal
                    ? postPlacementData?.retention.normal
                    : ''
                }
                footer="Retention Requests"
                onClick={() => handleCardClick('retention')}
                variant={group === 'retention' ? 'primary' : 'secondary'}
              />
            </Grid>
            <Grid item xl={3} sm={3}>
              <Card
                content={postPlacementData?.status.total}
                warning={
                  postPlacementData?.status.warning
                    ? postPlacementData?.status.warning
                    : ''
                }
                error={
                  postPlacementData?.status.attention
                    ? postPlacementData?.status.attention
                    : ''
                }
                success={
                  postPlacementData?.status.normal
                    ? postPlacementData?.status.normal
                    : ''
                }
                footer="Status Updates"
                onClick={() => handleCardClick('status')}
                variant={group === 'status' ? 'primary' : 'secondary'}
              />
            </Grid>
            {!isInternal && (
              <Grid item xl={3} sm={3}>
                <Card
                  content={postPlacementData?.myRequests.total}
                  warning={
                    postPlacementData?.myRequests.warning
                      ? postPlacementData?.myRequests.warning
                      : ''
                  }
                  error={
                    postPlacementData?.myRequests.attention
                      ? postPlacementData?.myRequests.attention
                      : ''
                  }
                  success={
                    postPlacementData?.myRequests.normal
                      ? postPlacementData?.myRequests.normal
                      : ''
                  }
                  footer="My Requests"
                  onClick={() => handleCardClick('myRequests')}
                  variant={group === 'myRequests' ? 'primary' : 'secondary'}
                />
              </Grid>
            )}
            <Grid item xl={3} sm={3}>
              <Card
                content={postPlacementData?.closed.total}
                warning={
                  postPlacementData?.closed.warning
                    ? postPlacementData?.closed.warning
                    : ''
                }
                error={
                  postPlacementData?.closed.attention
                    ? postPlacementData?.closed.attention
                    : ''
                }
                success={
                  postPlacementData?.closed.normal
                    ? postPlacementData?.closed.normal
                    : ''
                }
                footer="Closed Accounts"
                onClick={() => handleCardClick('closed')}
                variant={group === 'closed' ? 'primary' : 'secondary'}
              />
            </Grid>
            <Grid item xl={3} sm={3}>
              <Card
                content={postPlacementData?.completed.total}
                warning={
                  postPlacementData?.completed.warning
                    ? postPlacementData?.completed.warning
                    : ''
                }
                error={
                  postPlacementData?.completed.attention
                    ? postPlacementData?.completed.attention
                    : ''
                }
                success={
                  postPlacementData?.completed.normal
                    ? postPlacementData?.completed.normal
                    : ''
                }
                footer="Completed"
                onClick={() => handleCardClick('completed')}
                variant={group === 'completed' ? 'primary' : 'secondary'}
              />
            </Grid>
          </Grid>
        </Skeleton>
      </Box>
      <Box mt={7} display="flex" justifyContent="flex-end" mr={1}>
        <Button onClick={handleCreateNewRequestButton}>
          Create a new request
        </Button>
      </Box>
      <Box mt={3}>
        <DataTable
          sortable={true}
          useFilterMenu={true}
          isLoading={isLoadingPostPlacementItems}
          height="100%"
          maxHeight="100%"
          gridColumns={getColumns() || []}
          gridState={gridState}
          data={requestsData}
          pageable={true}
          total={totalRequests}
          onRowClick={handleRowClick}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
          }}
        />
      </Box>
    </Page>
  )
}

export default PostPlacementManager
