/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState, useContext, useEffect } from 'react'
import {
  Typography,
  Button,
  Colors,
  Grid,
  Box,
  DataTable,
  renderYesOrNoBoolean,
  renderEnabledColoredBoolean,
  DataTableState,
  ModalDialog,
  renderCellTableActions,
  Content,
  headerActions,
  Checkbox,
  Group,
  Flex,
} from 'everchain-uilibrary'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import {
  Strategy,
  UpdateStrategyStatus,
  DeleteStrategy,
  CloneStrategy,
} from 'src/domain/models/strategy'
import Creditors from '../../../Accounts/components/Creditors'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { useHistory } from 'react-router-dom'
import { STRATEGY } from 'src/presentation/routes'
import { IStrategyPostOperations } from 'src/domain/features/post/strategy/strategy'
import { useMessageSnackbar } from 'src/utils/notification'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import { refetchQueriesWrapper, useCustomQuery } from 'src/infra/reactQuery'
import { useQueryClient } from '@tanstack/react-query'
import { getStandardUri } from 'src/utils/common'
import { useRecoverGridFilter } from 'src/context/RecoverGridFilterContext'

interface StrategyParams {
  strategyOperations?: IStrategyGetOperations
  strategyPostOperations?: IStrategyPostOperations
  businessOperations?: IBusinessGetOperations
}
const StrategyList: React.FC<StrategyParams> = ({
  strategyOperations,
  businessOperations,
  strategyPostOperations,
}) => {
  const { placerFilter, setPlacerFilter } = useRecoverGridFilter()
  const history = useHistory()
  const ability = useContext(AbilityContext)
  const { showSuccessMessage } = useMessageSnackbar()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentItem, setCurrentItem] = useState<number>(0)
  const [onlyEnabled, setOnlyEnabled] = useState<boolean>(false)

  const getPersistedData = () => {
    if (window.localStorage.getItem('strategiesLoaded') !== null) {
      return JSON.parse(window.localStorage.getItem('strategiesLoaded') || '')
    } else {
    }
  }

  const [creditor, setCreditor] = useState<string>(
    getPersistedData()?.creditorId || placerFilter || ''
  )
  const [currentCreditorName, setCurrentCreditorName] = useState<string>(
    getPersistedData()?.currentCreditorName || ''
  )
  const [strategies, setStrategies] = useState<Strategy[] | undefined>(
    getPersistedData()?.strategies?.data || []
  )
  const [total, setTotal] = useState<number>(
    getPersistedData()?.strategies?.totalCount || 0
  )
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const queryClient = useQueryClient()

  const { data: strategiesData, isFetching: fetchingStrategies } =
    useCustomQuery(
      ['getStrategies', gridState, creditor, onlyEnabled],
      async () =>
        strategyOperations?.getStrategies(gridState, creditor, onlyEnabled),
      { cacheTime: 0, staleTime: 0 }
    )

  useEffect(() => {
    if (!fetchingStrategies) {
      setStrategies(strategiesData?.data)
      setTotal(strategiesData?.totalCount ?? 0)
      window.localStorage.setItem(
        'strategiesLoaded',
        JSON.stringify({
          strategies: strategiesData,
          creditorId: creditor,
          currentCreditorName: currentCreditorName,
        })
      )
    }
  }, [fetchingStrategies])

  const GridColumns = () => {
    const columns: any[] = [
      { field: 'name', title: 'Name', show: true, width: 200 },
      {
        field: 'stages',
        title: 'Stages',
        show: true,
        width: 300,
        notFilterable: true,
      },
      {
        field: 'totalDuration',
        title: 'Total Duration',
        show: true,
        width: 125,
        filter: 'numeric',
      },
      {
        field: 'dynamicDistribution',
        title: 'Dynamic Allocation',
        render: renderYesOrNoBoolean,
        show: true,
        filter: 'boolean',
        width: 155,
      },
      {
        field: 'enabled',
        title: 'Status',
        render: renderEnabledColoredBoolean,
        show: true,
        filter: 'boolean',
        width: 97,
      },
      {
        field: 'hasBeenUsed',
        title: 'Has been used?',
        render: renderYesOrNoBoolean,
        show: true,
        filter: 'boolean',
        width: 135,
      },
      {
        field: 'chargeOffType',
        title: 'Charge-Off Type',
        show: true,
        filter: 'boolean',
        width: 150,
      },
      {
        title: 'Actions',
        render: (props: any) =>
          renderCellTableActions(
            props,
            getActions(
              Boolean(props.dataItem['hasBeenUsed']),
              Boolean(props.dataItem['enabled'])
            )
          ),
        headerCell: headerActions,
        show: true,
        width: 60,
      },
    ]

    return columns
  }

  const actions = (enabled: boolean) => [
    {
      name: enabled ? 'Disable' : 'Enable',
      onClick: (tableCell: any) => {
        const params: UpdateStrategyStatus = {
          id: tableCell.dataItem['id'],
          enabled: !enabled,
        }
        strategyPostOperations
          ?.updateStrategyStatus(params)
          .then((response: any) => {
            refetchQueriesWrapper(
              ['getStrategies', gridState, creditor, onlyEnabled],
              queryClient
            )
            showSuccessMessage('Strategy updated successfully')
          })
      },
      loading: false,
    },

    {
      name: 'Clone',
      onClick: (tableCell: any) => {
        const params: CloneStrategy = {
          id: tableCell.dataItem['id'],
        }
        strategyPostOperations?.cloneStrategy(params).then((response: any) => {
          refetchQueriesWrapper(
            ['getStrategies', gridState, creditor, onlyEnabled],
            queryClient
          )
          showSuccessMessage('Strategy cloned successfully')
        })
      },
      loading: false,
    },
  ]
  const getActions = (hasBeenUsed: boolean, enabled: boolean) => {
    let items: any = []
    const canDeleteStrategy = ability.can(
      PermissionCodeAccess.Recover_DeleteStrategies,
      'any'
    )
    const canEdit = ability.can(
      PermissionCodeAccess.Recover_UpdateStrategies,
      'any'
    )
    if (canEdit) {
      items.push({
        name: 'Edit',
        onClick: (tableCell: any) => {
          history.push(getStandardUri(`${STRATEGY}/${tableCell.dataItem.id}`))
        },
        loading: false,
      })
    }

    if (!hasBeenUsed && canDeleteStrategy) {
      items.push({
        name: 'Delete',
        onClick: (tableCell: any) => {
          setIsOpen(true)
          setCurrentItem(tableCell.dataItem['id'])
        },
        loading: false,
      })
    }
    actions(enabled).forEach((action: any) => {
      items.push(action)
    })

    return items
  }
  const handleCreditorsUpdate = (creditorId: any, creditorName: any) => {
    setCreditor(creditorId)
    setPlacerFilter(creditorId)
    setCurrentCreditorName(creditorName)
  }

  return (
    <Content>
      <Grid id="strategy-page">
        <Flex maxWidth="100%" alignItems="center">
          <Typography variant="h6" color={Colors.black}>
            {currentCreditorName
              ? `Strategy - ${currentCreditorName.split('(')[0]} Strategies`
              : 'Strategy'}
          </Typography>
          <Typography
            variant="body1"
            color={Colors.darkGray}
            style={{ marginLeft: '5px' }}
          >
            {currentCreditorName ? ` | ${total} results` : ''}
          </Typography>
        </Flex>

        <Box maxWidth="100%" display="flex" justifyContent="space-between">
          <Group alignItems="flex-end">
            <Creditors
              businessOperations={businessOperations}
              onCreditorsUpdate={handleCreditorsUpdate}
              selectedCreditor={creditor || ''}
            />
            <Checkbox
              label="Show Only Enabled Strategies"
              onChange={() => {
                setOnlyEnabled(!onlyEnabled)
              }}
              paddingbottom="3px"
            />
          </Group>
          {ability.can(
            PermissionCodeAccess.Recover_CreateStrategies,
            'any'
          ) && (
            <Box>
              <Button
                id="button-add-new-strategy"
                style={{ marginTop: '20px' }}
                onClick={() => history.push(getStandardUri(STRATEGY))}
                variant="primary"
                bold
              >
                Add New Strategy
              </Button>
            </Box>
          )}
        </Box>
        <Box mt={5}>
          <DataTable
            useFilterMenu
            isLoading={fetchingStrategies}
            height="100%"
            maxHeight="100%"
            gridColumns={GridColumns()}
            gridState={gridState}
            data={strategies || []}
            pageable={true}
            total={total}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
              if (strategies) {
                refetchQueriesWrapper(
                  ['getStrategies', gridState, creditor, onlyEnabled],
                  queryClient
                )
              }
            }}
          />
        </Box>
      </Grid>
      <ModalDialog
        header="Delete Strategy"
        isOpen={isOpen}
        buttonCancelText="Cancel"
        buttonOkText="Delete"
        onClose={() => setIsOpen(false)}
        onContinue={() => {
          setIsOpen(false)
          const params: DeleteStrategy = {
            strategyId: currentItem,
          }
          strategyPostOperations
            ?.deleteStrategy(params)
            .then((response: any) => {
              refetchQueriesWrapper(
                ['getStrategies', gridState, creditor, onlyEnabled],
                queryClient
              )
              showSuccessMessage('Strategy deleted successfully')
            })
        }}
      >
        <Typography color={Colors.primary} variant="caption">
          Are you sure that you want to delete this strategy? This action can't
          be undone.
        </Typography>
      </ModalDialog>
    </Content>
  )
}

export default StrategyList
