import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import {
  CardItemResponse,
  DataResponse,
  ValueLabelDto,
} from 'src/domain/models/common'
import { DataTableState } from 'everchain-uilibrary'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import * as types from 'src/domain/models/complaint'
import * as urls from './urls'

export class ComplaintGetData implements IComplaintGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getComplaints(
    pagination: DataTableState,
    complaintCard: string
  ): Promise<DataResponse<types.ComplaintDto>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const response = await this.httpClient.get(
      `${urls.ComplaintsGetComplaints}?pagination=${encodedData}&complaintCard=${complaintCard}`
    )
    return response.data
  }

  async getPreview(ecaid: string): Promise<types.ComplaintDto> {
    const response = await this.httpClient.get(
      `${urls.ComplaintsGetPreview}?ecaid=${ecaid}`
    )
    return response.data
  }

  async getComplaintById(id: number): Promise<types.ComplaintDto> {
    const response = await this.httpClient.get(
      `${urls.ComplaintsGetById}?complaintId=${id}`
    )
    return response.data
  }

  async getComplaintStatuses(): Promise<ValueLabelDto[]> {
    const response = await this.httpClient.get(urls.ComplaintsGetStatuses)
    return response.data
  }

  async getComplaintViolationTypes(): Promise<ValueLabelDto[]> {
    const response = await this.httpClient.get(urls.ComplaintsGetViolationTypes)
    return response.data
  }

  async getComplaintOutcomes(): Promise<ValueLabelDto[]> {
    const response = await this.httpClient.get(urls.ComplaintsGetOutcomes)
    return response.data
  }

  async getComplaintAccounts(
    pagination: DataTableState,
    creditorId?: string,
    vendorId?: string,
    last4SSN?: string,
    accountIDs?: string
  ): Promise<types.GetComplaintAccountsDto> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const creditorFilter = creditorId ? `&creditorId= ${creditorId} ` : ''
    const vendorFilter = vendorId ? `&vendorId= ${vendorId} ` : ''
    const last4SSNFilter = last4SSN ? `&last4SSN=${last4SSN} ` : ''
    const accountIDsFilter = accountIDs ? `&accountIDs= ${accountIDs} ` : ''

    const response = await this.httpClient.get(
      `${urls.ComplaintsGetAccounts}?pagination=${encodedData}${vendorFilter}${creditorFilter}${last4SSNFilter}${accountIDsFilter}`
    )
    return response.data
  }

  async getComplaintSourceTypes(): Promise<ValueLabelDto[]> {
    const response = await this.httpClient.get(urls.ComplaintsGetSourceTypes)
    return response.data
  }

  async getComplaintAccountStatuses(): Promise<ValueLabelDto[]> {
    const response = await this.httpClient.get(
      urls.ComplaintsGetAccountStatuses
    )
    return response.data
  }

  async getComplaintCategories(): Promise<string[]> {
    const response = await this.httpClient.get(urls.ComplaintsGetCategories)
    return response.data
  }

  async getComplaintCards(): Promise<CardItemResponse[]> {
    const response = await this.httpClient.get(urls.ComplaintsGetCards)
    return response.data
  }

  async getComplaintComments(
    complaintId: number,
    pagination: DataTableState
  ): Promise<DataResponse<types.ComplaintCommentDto>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const response = await this.httpClient.get(
      `${urls.ComplaintsGetComments}?complaintId=${complaintId}&pagination=${encodedData}`
    )

    return response.data
  }

  async getComplaintInfoRequests(
    complaintId: number,
    pagination: DataTableState
  ): Promise<DataResponse<types.ComplaintInfoRequestDto>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const response = await this.httpClient.get(
      `${urls.ComplaintsGetInfoRequests}?complaintId=${complaintId}&pagination=${encodedData}`
    )

    return response.data
  }

  async getComplaintInfoRequestDescriptions(): Promise<string[]> {
    const response = await this.httpClient.get(
      urls.ComplaintsGetInfoRequestDescriptions
    )
    return response.data
  }

  async getComplaintInfoRequestStatuses(): Promise<string[]> {
    const response = await this.httpClient.get(
      urls.ComplaintsGetInfoRequestStatuses
    )
    return response.data
  }

  async getComplaintAttachments(
    complaintId: number
  ): Promise<types.GetComplaintAttachmentsDto[]> {
    const response = await this.httpClient.get(
      `${urls.ComplaintsGetAttachments}?complaintId=${complaintId}`
    )
    return response.data
  }

  async getUriComplaintAttachment(
    complaintAttachmentId: number
  ): Promise<string> {
    const response = await this.httpClient.get(
      `${urls.ComplaintsGetUriAttachment}?complaintAttachmentId=${complaintAttachmentId}`
    )
    return response.data
  }
}
