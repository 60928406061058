import { ACCOUNT_URL } from 'src/infra/http/httpClient'

const postPlacementPrefix = 'postPlacement.'

export const PostPlacementCreateRequest = `${ACCOUNT_URL}${postPlacementPrefix}createPostPlacementRequest`
export const PostPlacementAddComment = `${ACCOUNT_URL}${postPlacementPrefix}addPostPlacementComment`
export const PostPlacementAcceptPostPlacement = `${ACCOUNT_URL}${postPlacementPrefix}acceptPostPlacement`
export const PostPlacementSubmitPostPlacement = `${ACCOUNT_URL}${postPlacementPrefix}submitPostPlacement`
export const PostPlacementRejectPostPlacement = `${ACCOUNT_URL}${postPlacementPrefix}rejectPostPlacement`
export const PostPlacementWithdrawPostPlacement = `${ACCOUNT_URL}${postPlacementPrefix}withdrawPostPlacement`
export const PostPlacementBulkRespond = `${ACCOUNT_URL}${postPlacementPrefix}bulkRespond`
