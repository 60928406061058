import {
  Box,
  Button,
  Checkbox,
  DataTable,
  DataTableState,
  Dropdown,
  ErrorToast,
  Flex,
  fParseXlsxFile,
  Grid,
  Group,
  Icon,
  ModalDialog,
  renderDate,
  renderYesOrNoBoolean,
  Skeleton,
  Typography,
  UploadItem,
} from 'everchain-uilibrary'
import React, { Dispatch, useContext, useState } from 'react'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { BULK_POST_PLACEMENT_RESPOND } from 'src/presentation/routes'
import { getStandardUri } from 'src/utils/common'
import Last4SSNInput from '../../Accounts/components/Last4SSNInput'
import AccountIDsInput from '../../Accounts/components/AccountIdsInput'
import Creditors from '../../Accounts/components/Creditors'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useCustomQuery } from 'src/infra/reactQuery'
import PostPlacementTypes from '../../Accounts/components/PostPlacementTypes'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { TdNoEllipsis } from 'src/presentation/styles/layout'
import {
  DropzoneErrorCodes,
  InfoRequestsGroup,
  PostPlacementTypeEnum,
  RecallReturnRequestsGroup,
  StatusUpdateRequestsGroup,
} from 'src/utils/constants'
import { useDropzone } from 'react-dropzone'
import { DropUpload } from 'src/presentation/styles/mediaUpload'
import Page from '../../Common/Page'

export interface BulkPostPlacementBaseFormFilter {
  creditorId: string | undefined
  vendorId: string | undefined
  last4SSN: string | undefined
  accountIDs: string | undefined
  postPlacementType: string | undefined
  type: number | undefined
}

interface BulkPostPlacementBasePageParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations?: IPostPlacementGetOperations
  pageTitle: string
  showActionDropDown: boolean
  formFilter: BulkPostPlacementBaseFormFilter | undefined
  setForm: Dispatch<React.SetStateAction<BulkPostPlacementBaseFormFilter>>
  mutationGetTemplate: UseMutationResult<any, unknown, void, unknown>
  fileHeaders: string[]
  processFile: (rawData: any[]) => void
  mutationUploadFile: UseMutationResult<any, any, void, unknown>
  isProcessing: boolean
  showUploadDialog: boolean
  setShowUploadDialog: Dispatch<React.SetStateAction<boolean>>
  action: string
  setAction: Dispatch<React.SetStateAction<string>>
  setPlacerFilter: (v: string | undefined) => void
  fileName: string
  setFileName: any
}

interface Options {
  label: string
  value: string
}

const BulkPostPlacementBasePage: React.FC<BulkPostPlacementBasePageParams> = ({
  businessOperations,
  postPlacementOperations,
  pageTitle,
  showActionDropDown,
  formFilter,
  setForm,
  mutationGetTemplate,
  fileHeaders,
  processFile,
  mutationUploadFile,
  isProcessing,
  showUploadDialog,
  setShowUploadDialog,
  action,
  setAction,
  setPlacerFilter,
  setFileName,
  fileName,
}) => {
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const { isVendor, isCreditor } = useContext(AuthContext)
  const operationType = isVendor ? 2 : 1
  const [fetchingRequests, setIsFetchingRequests] = useState<boolean>(false)
  const [requestsSelected, setRequestsSelected] = useState<string[]>([])
  const [typeName, setTypeName] = useState<string>('')
  const [fileBulk, setFileBulk] = useState<any[]>([])
  const [rawData, setRawData] = useState<any[]>([])

  const MAX_SIZE_FILE = 5368709120 // 5GB

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const [requests, setRequests] = useState<any[] | undefined>()

  const [totalRequests, setTotalRequests] = useState<number>()

  const mutationGetRequests = useMutation({
    mutationFn: async () => {
      setIsFetchingRequests(true)
      return postPlacementOperations?.getRequests(
        formFilter?.type || 0,
        gridState,
        formFilter?.creditorId,
        formFilter?.vendorId,
        formFilter?.last4SSN,
        formFilter?.accountIDs
      )
    },
    onSuccess: async (response: any) => {
      const data = response?.data.data.map((item: any) => ({
        ...item,
        ...JSON.parse(item.specificDataJson),
      }))
      setIsFetchingRequests(false)
      setRequests(data)
      setTotalRequests(response?.totalCount ?? 0)
    },
    onError: async (response: any) => {
      setIsFetchingRequests(false)
    },
  })

  const handleCreditorsUpdate = (creditor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, creditorId: creditor }
    })
  }

  const handleTypessUpdate = (type: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, type: Number(type?.split('#')[0]) }
    })
    setTypeName(type?.split('#')[1])
  }

  const handleAgencyUpdate = (vendor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, vendorId: vendor }
    })
  }

  const handleLast4Update = (last4SSN: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, last4SSN: last4SSN.currentTarget.value }
    })
  }

  const handleAccountIDsUpdate = (accountIDs: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, accountIDs: accountIDs.currentTarget.value }
    })
  }

  const { data: dataBusiness, isLoading: loadingAgencies } = useCustomQuery(
    ['getPlacedAccounts', formFilter?.creditorId],
    async () =>
      businessOperations?.getPlacedBusiness(
        [formFilter?.creditorId || ''],
        operationType
      ),
    { cacheTime: 0, enabled: !!formFilter?.creditorId }
  )

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: item.businessName,
        value: item.businessId,
      })
    )
    return newOptions
  }

  const handleFilterClick = () => {
    setPlacerFilter(formFilter?.creditorId)
    mutationGetRequests.mutate()
  }

  const options = getDropDownOptions(dataBusiness?.businessList || [])

  const handleAddCheck = (props: any) => {
    if (
      requestsSelected.find((id) => id === props.dataItem['ecaid']) !==
      undefined
    )
      return true

    return false
  }

  const onFileBulkDrop = (acceptedFiles: any) => {
    fParseXlsxFile(acceptedFiles[0]).then((result: any) => {
      if (result.data?.length === 0) {
        ErrorToast('File is empty.')
        return
      }

      const notFound =
        result.data &&
        fileHeaders.filter((key) => {
          return !Object.keys(result.data[0]).find(
            (x: any) => x.toLowerCase().trim() === key.toLowerCase().trim()
          )
        })
      if (notFound.length > 0) {
        ErrorToast(
          `Error uploading bulk file. Header(s) not found ${notFound.join(
            ', '
          )}`
        )
        return
      }
      if (!result.passedValidation) {
        ErrorToast(`Error uploading bulk file. ${result.errorMessage}`)
        return
      }
      setFileName(acceptedFiles[0]?.name)
      setFileBulk(acceptedFiles)
      setRawData(result.data)
    })
  }

  const handleFileBulkRejected = (data: any) => {
    const message =
      data[0].errors[0].code === DropzoneErrorCodes.INVALID_TYPE_FILE
        ? 'Only .csv and .xlsx files are accepted.'
        : data[0].errors[0].code === DropzoneErrorCodes.FILE_TOO_BIG
        ? 'File is over the 5GB limit.'
        : ''
    ErrorToast(`The file has been rejected. ${message}`)
  }

  const {
    getRootProps: getFileBookRootProps,
    getInputProps: geFileBookInputProps,
    isDragActive: isFileBookDragActive,
  } = useDropzone({
    onDrop: onFileBulkDrop,
    multiple: false,
    maxSize: MAX_SIZE_FILE,
    accept:
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDropRejected: handleFileBulkRejected,
  })

  const handleUploadFile = () => {
    processFile(rawData)
    mutationUploadFile.mutate()
    setFileBulk([])
    setRawData([])
  }

  const GridColumns: any[] = [
    {
      title: 'Select',
      width: 75,
      show: true,
      notFilterable: true,
      render: (props: any) => {
        return (
          <TdNoEllipsis>
            <Checkbox
              onChange={(event: any) => {
                if (event) {
                  setRequestsSelected([
                    ...requestsSelected,
                    props.dataItem['ecaid'],
                  ])
                } else {
                  setRequestsSelected(
                    requestsSelected.filter(
                      (id) => id !== props.dataItem['ecaid']
                    )
                  )
                }
              }}
              checked={handleAddCheck(props)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'id',
      title: 'ID',
      show: true,
      width: 75,
    },
    {
      field: 'creditor',
      title: 'Creditor',
      show: true,
      width: 200,
    },
    {
      field: 'loanId',
      title: 'Loan ID',
      show: true,
      width: 200,
    },
    {
      field: 'status',
      title: 'Status',
      show: true,
      width: 200,
    },
    {
      field: 'modifiedAt',
      title: 'Last Updated',
      show: true,
      width: 130,
      render: renderDate,
    },
    {
      field: 'lastName',
      title: 'Last Name',
      width: 150,
      show: !isInternal,
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: 150,
      show: !isInternal,
    },
    {
      field: 'ecaid',
      title: 'ECAID',
      show: true,
      width: 300,
      filter: 'text',
    },
  ]

  const getGridColumns = (): any[] => {
    const requestTypeId = formFilter?.type
    if (requestTypeId === PostPlacementTypeEnum.Bankrupt) {
      return GridColumns.concat([
        {
          field: 'IsStatusUpdate',
          title: 'Is Status Update',
          width: 160,
          show: true,
          editable: true,
          render: renderYesOrNoBoolean,
        },
        {
          field: 'CaseNumber',
          title: 'Case Number',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'Chapter',
          title: 'Chapter',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'FileDate',
          title: 'File Date',
          width: 120,
          show: true,
          editable: true,
          render: renderDate,
        },
        {
          field: 'AttorneyName',
          title: 'Attorney Name',
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'AttorneyPhone',
          title: 'Attorney Phone',
          width: 150,
          show: true,
          editable: true,
        },
        {
          field: 'District',
          title: 'District',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.Deceased) {
      return GridColumns.concat([
        {
          field: 'IsStatusUpdate',
          title: 'Is Status Update',
          width: 160,
          show: true,
          editable: true,
          render: renderYesOrNoBoolean,
        },
        {
          field: 'DateOfDeath',
          title: 'Date Of Death',
          show: true,
          editable: true,
          width: 140,
          render: renderDate,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.Info) {
      return GridColumns.concat([
        {
          field: 'InfoRequested',
          title: 'Info Requested',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'InfoType',
          title: 'Info Type',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (
      requestTypeId === PostPlacementTypeEnum.Legal ||
      requestTypeId === PostPlacementTypeEnum.Fraud ||
      requestTypeId === PostPlacementTypeEnum.Compliance
    ) {
      return GridColumns.concat([
        {
          field: 'IsStatusUpdate',
          title: 'Is Status Update',
          width: 160,
          show: true,
          editable: true,
          render: renderYesOrNoBoolean,
        },
        {
          field: 'Type',
          title: 'Type',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'Explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.Retain) {
      return GridColumns.concat([
        {
          field: 'Explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
        {
          field: 'NumberOfDays',
          title: 'Number Of Days',
          width: 150,
          show: true,
          editable: true,
        },
      ])
    }

    if (
      requestTypeId === PostPlacementTypeEnum.Other ||
      requestTypeId === PostPlacementTypeEnum.InaccurateData ||
      requestTypeId === PostPlacementTypeEnum.DebtSettlement ||
      requestTypeId === PostPlacementTypeEnum.CCCS ||
      requestTypeId === PostPlacementTypeEnum.SCRA
    ) {
      return GridColumns.concat([
        {
          field: 'IsStatusUpdate',
          title: 'Is Status Update',
          width: 160,
          show: true,
          editable: true,
          render: renderYesOrNoBoolean,
        },
        {
          field: 'Explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.PaidPrior) {
      return GridColumns.concat([
        {
          field: 'IsStatusUpdate',
          title: 'Is Status Update',
          width: 160,
          show: true,
          editable: true,
        },
        {
          field: 'PaidPriorDate',
          title: 'Paid Prior Date',
          width: 140,
          show: true,
          editable: true,
          render: renderDate,
        },
        {
          field: 'PaidPriorType',
          title: 'Paid Prior Type',
          show: true,
          editable: true,
          width: 150,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.LowBalance) {
      return GridColumns.concat([
        {
          field: 'IsStatusUpdate',
          title: 'Is Status Update',
          width: 160,
          show: true,
          editable: true,
        },
        {
          field: 'TotalBalanceAtClosure',
          title: 'Total Balance At Closure',
          show: true,
          editable: true,
          width: 200,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.OutOfStat) {
      return GridColumns.concat([
        {
          field: 'IsStatusUpdate',
          title: 'Is Status Update',
          width: 160,
          show: true,
          editable: true,
        },
        {
          field: 'OutOfStatuteDate',
          title: 'Out Of Statute Date',
          show: true,
          editable: true,
          width: 170,
          render: renderDate,
        },
        {
          field: 'OutOfStatuteState',
          title: 'Out Of Statute State',
          show: true,
          editable: true,
          width: 175,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.PifSif) {
      return GridColumns.concat([
        {
          field: 'IsStatusUpdate',
          title: 'Is Status Update',
          width: 160,
          show: true,
          editable: true,
        },
        {
          field: 'PifSifType',
          title: 'PIF/SIF Type',
          show: true,
          editable: true,
          width: 200,
        },
        {
          field: 'PaymentDate',
          title: 'Payment Date',
          show: true,
          editable: true,
          width: 160,
          render: renderDate,
        },
        {
          field: 'PaymentAmount',
          title: 'Payment Amount',
          show: true,
          editable: true,
          width: 160,
        },
        {
          field: 'CheckOrReferenceNumber',
          title: 'Check Or Reference Number',
          show: true,
          editable: true,
          width: 230,
        },
        {
          field: 'LocationOrStore',
          title: 'Location Or Store',
          show: true,
          editable: true,
          width: 170,
        },
        {
          field: 'ConsumerAddress',
          title: 'Consumer Address',
          show: true,
          editable: true,
          width: 170,
        },
        {
          field: 'ConsumerCity',
          title: 'Consumer City',
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'ConsumerState',
          title: 'Consumer State',
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'ConsumerPostalCode',
          title: 'Consumer Postal Code',
          show: true,
          editable: true,
          width: 190,
        },
        {
          field: 'ConsumerPhoneNumber',
          title: 'Consumer Phone Number',
          show: true,
          editable: true,
          width: 210,
        },
      ])
    }

    if (requestTypeId === PostPlacementTypeEnum.CreditBureauReporting) {
      return GridColumns.concat([
        {
          field: 'IsStatusUpdate',
          title: 'Is Status Update',
          width: 160,
          show: true,
          editable: true,
        },
        {
          field: 'CreditBureauType',
          title: 'Credit Bureau Type',
          width: 170,
          show: true,
          editable: true,
        },
        {
          field: 'TradelineAdded',
          title: 'Tradeline Added',
          width: 160,
          show: true,
          editable: true,
        },
        {
          field: 'TradelineRemoved',
          title: 'Tradeline Removed',
          width: 180,
          show: true,
          editable: true,
        },
        {
          field: 'Explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    return GridColumns
  }

  return (
    <Page
      title={`Bulk Post Placement ${pageTitle} ${
        typeName ? `- Type: ${typeName}` : ''
      }`}
      customBackUrl={getStandardUri(BULK_POST_PLACEMENT_RESPOND)}
    >
      <Flex justifyContent={'space-between'}>
        <Group>
          <>
            <PostPlacementTypes
              businessOperations={businessOperations}
              onTypesUpdate={handleTypessUpdate}
              selectedType={formFilter?.type?.toString()}
            ></PostPlacementTypes>
            <Creditors
              businessOperations={businessOperations}
              onCreditorsUpdate={handleCreditorsUpdate}
              selectedCreditor={formFilter?.creditorId}
            />
            <Dropdown
              id="agency-dropdown"
              maxwidth="200px"
              width="200px"
              options={options}
              value={formFilter?.vendorId || undefined}
              onChange={(option?: Options) => {
                handleAgencyUpdate(option?.value)
              }}
              disabled={!formFilter?.creditorId}
              placeholder="Agency"
              isLoading={loadingAgencies && !!formFilter?.creditorId}
            />
            <Last4SSNInput isForm={false} onBlur={handleLast4Update} />
            <AccountIDsInput isForm={false} onBlur={handleAccountIDsUpdate} />
          </>
        </Group>
        <Button
          useRipple
          width={80}
          height={40}
          disabled={
            (!isInternal &&
              ((isCreditor && formFilter?.creditorId == null) ||
                (isVendor && formFilter?.vendorId == null))) ||
            !formFilter?.type
          }
          onClick={handleFilterClick}
        >
          Filter
        </Button>
      </Flex>
      <Box mt={5}>
        <Grid item style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Box>
            <Typography style={{ marginBottom: 0 }}>
              Total of accounts selected: {requestsSelected.length}
            </Typography>
          </Box>
          {showActionDropDown &&
            RecallReturnRequestsGroup.concat(
              InfoRequestsGroup,
              StatusUpdateRequestsGroup
            ).includes(formFilter?.type || 0) && (
              <Box>
                <Dropdown
                  placeholder="Action"
                  value={action}
                  options={[
                    {
                      label: 'Response',
                      value: 'response',
                    },
                    {
                      label: 'Review',
                      value: 'review',
                    },
                  ]}
                  onChange={(props: any) => {
                    setAction(props?.value)
                  }}
                />
              </Box>
            )}
          <Box>
            <Button
              disabled={!formFilter?.type && formFilter?.type !== 0}
              onClick={() => mutationGetTemplate.mutate()}
            >
              Download Template
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => setShowUploadDialog(true)}
              disabled={!formFilter?.type && formFilter?.type !== 0}
              hidden={isInternal}
            >
              Upload Bulk {pageTitle} File
            </Button>
          </Box>
        </Grid>
      </Box>
      <Box mt={5}>
        <DataTable
          sortable={true}
          useFilterMenu={true}
          isLoading={fetchingRequests}
          height="100%"
          maxHeight="100%"
          gridColumns={getGridColumns() || []}
          gridState={gridState}
          data={requests}
          pageable={true}
          total={totalRequests}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
            mutationGetRequests.mutate()
          }}
        />
      </Box>
      <ModalDialog
        isOpen={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        aria-labelledby="form-filter"
        hideOkButton={true}
        hideCancelButton={true}
        header="Upload File"
        width="600px"
      >
        <Skeleton isLoading={isProcessing} width="100%" height="50px">
          <Box display="flex" flexDirection="column">
            <Box>
              {fileBulk.length === 0 ? (
                <>
                  <DropUpload
                    isDragActive={isFileBookDragActive}
                    {...getFileBookRootProps()}
                  >
                    <input {...geFileBookInputProps()} />
                    <div className="upload-placeholder">
                      <Icon name="CloudUpload" className="upload-icon" />{' '}
                      <strong>Choose a Bulk File </strong> or drop a bulk file
                      here to upload
                    </div>
                  </DropUpload>
                </>
              ) : (
                <UploadItem
                  file={fileBulk[0]}
                  removeFile={() => setFileBulk([])}
                ></UploadItem>
              )}
            </Box>
            <Box display="flex" justifyContent="end" mt={2}>
              <Button
                onClick={handleUploadFile}
                disabled={fileBulk?.length === 0}
              >
                Upload File
              </Button>
            </Box>
          </Box>
        </Skeleton>
      </ModalDialog>
    </Page>
  )
}

export default BulkPostPlacementBasePage
