import { breakpoints, Colors } from 'everchain-uilibrary'
import styled from 'styled-components'

interface DropUploadProps {
  isDragActive?: boolean
  disabled?: boolean
  ref?: any
  size?: 'small' | 'large'
}

export const DropUpload = styled.div<DropUploadProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  cursor: pointer;
  opacity: ${({ disabled }): number => (!disabled ? 1 : 0.3)};
  border: 1px dashed
    ${({ isDragActive }): string =>
      !isDragActive ? Colors.mediumGray : Colors.primary};
  padding: ${({ size }): string =>
    (size === 'small' && '1.2rem 0.9rem') ||
    (size === 'large' && '1.4rem 1.1rem') ||
    '1.5rem 1.2rem'};
  background-color: ${Colors.secondary};
  > .upload-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${Colors.darkGray};
    font-size: 0.9rem;
    ${breakpoints.up('sm')} {
      font-size: 0.7rem;
      justify-content: flex-start;
      flex-direction: row;
    }
    ${breakpoints.up('lg')} {
      font-size: ${({ size }): string =>
        (size === 'small' && '0.7rem') ||
        (size === 'large' && '1.3rem') ||
        '1.4rem'};
    }
    strong {
      color: ${Colors.primary};
      text-decoration: underline;
      margin-right: 0.3rem;
    }
    > .upload-icon {
      margin-right: 0.7rem;
    }
    > .upload-placeholder-padding {
      ${breakpoints.up('lg')} {
        padding-top: 10px;
      }
    }
  }
`

export const UploadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.lightGray};
  margin-top: 1.2rem;
  .upload-item-info {
    display: flex;
    align-items: center;
  }
  .upload-item-icon {
    margin-right: 0.4rem;
  }
`
