import React, { useContext, useState } from 'react'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import {
  Box,
  Button,
  Card,
  Content,
  fFormatDate,
  fNumberCurrency,
  Grid,
  Icon,
  Input,
  ModalDialog,
  Skeleton,
  SuccessToast,
  Summary,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/reactQuery'
import { profileCountry } from 'src/utils/user'
import { PostPlacementDetailsData } from 'src/domain/models/post-placement'
import { useParams } from 'react-router-dom'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getStandardUri } from 'src/utils/common'
import { Display } from '../../Accounts/AccountDetails/styles'
import PostPlacementSpecificData from './components/SpecificData'
import PostPlacementComment from './components/Comments'
import { useMutation } from '@tanstack/react-query'
import { PostPlacementTypeEnum } from 'src/utils/constants'
import Page from '../../Common/Page'
import PostPlacementDetails from './components/PostPlacementDetails'

interface PostPlacementParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations: IPostPlacementGetOperations
  postPlacementPostOperations: IPostPlacementPostOperations
}

interface RejectFormFilter {
  rejectReason: string
}

const PostPlacementDetail: React.FC<PostPlacementParams> = ({
  postPlacementOperations,
  postPlacementPostOperations,
}) => {
  const { requestId, backUrl } = useParams<any>()
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [isAccepting, setIsAccepting] = useState<boolean>(false)
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)
  const [isRejecting, setIsRejecting] = useState<boolean>(false)
  const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false)
  const [rejectModalIsOpen, setRejectModalIsOpen] = useState<boolean>(false)
  const [withdrawModalIsOpen, setOpenWithdrawDialog] = useState<boolean>(false)
  const [rejectForm, setRejectForm] = useState<RejectFormFilter | undefined>()
  const [withdrawForm, setWithdrawForm] = useState<
    RejectFormFilter | undefined
  >()
  const { isVendor, isCreditor } = useContext(AuthContext)

  const {
    data: postPlacementData,
    isLoading: isLoadingPostPlacement,
    refetch: refetchDetails,
    isRefetching: isRefetchingPostPlacement,
  } = useCustomQuery<PostPlacementDetailsData>(
    ['getPostPlacementDetails'],
    async () => postPlacementOperations?.getPostPlacementDetails(requestId),
    { cacheTime: 0 }
  )

  const createCardTitle = () => {
    if (!postPlacementData) return ''

    if (isInternal) return postPlacementData?.lenderLoanId

    const consumerName = getConsumerName()

    return `${consumerName} | ${postPlacementData?.lenderLoanId}`
  }

  const AllowAccept = (): boolean => {
    if (isInternal) {
      return false
    }

    const requestType = postPlacementData?.requestType || 0
    const status = postPlacementData?.status.toLowerCase() || ''

    if ([PostPlacementTypeEnum.PifSif].includes(requestType)) return false

    if (
      [
        'withdrawn',
        'closed',
        'unresolved',
        'rejected',
        'pending placer funding',
        'pending agency funding',
        'pending placer review',
        'pending agency review',
      ].includes(status)
    ) {
      return false
    }

    if (isVendor && status === 'pending placer response') {
      return false
    }

    if (isCreditor && status === 'pending agency response') {
      return false
    }

    return true
  }

  const AllowConfirmReject = (): boolean => {
    if (isInternal) {
      return false
    }

    const requestType = postPlacementData?.requestType || 0
    const status = postPlacementData?.status.toLowerCase() || ''

    if ([PostPlacementTypeEnum.PifSif].includes(requestType)) return false

    if (
      [
        'withdrawn',
        'closed',
        'unresolved',
        'rejected',
        'pending placer funding',
        'pending agency funding',
        'pending placer review',
        'pending agency review',
      ].includes(status)
    ) {
      return false
    }

    if (isVendor && status === 'pending placer response') {
      return false
    }

    if (isCreditor && status === 'pending agency response') {
      return false
    }

    return true
  }

  const AllowWithdraw = (): boolean => {
    if (isInternal) {
      return false
    }
    const status = postPlacementData?.status.toLowerCase() || ''

    if (['withdrawn', 'closed', 'new'].includes(status)) {
      return false
    }

    if (
      (isVendor && !postPlacementData?.initiatedByAgency) ||
      (isCreditor && postPlacementData?.initiatedByAgency)
    ) {
      return false
    }

    if (['pending placer review'].includes(status) && !isCreditor) {
      return false
    }

    if (['pending agency review'].includes(status) && !isVendor) {
      return false
    }

    return true
  }

  const AllowSubmitRequested = (): boolean => {
    if (isInternal) {
      return false
    }

    const requestType = postPlacementData?.requestType || 0

    if (
      [PostPlacementTypeEnum.PifSif, PostPlacementTypeEnum.Retain].includes(
        requestType
      )
    ) {
      return false
    }

    const status = postPlacementData?.status.toLowerCase() || ''

    if (
      [
        'withdrawn',
        'closed',
        'unresolved',
        'new',
        'rejected',
        'pending placer response',
        'pending agency response',
        'pending placer funding',
        'pending agency funding',
      ].includes(status.toLowerCase())
    ) {
      return false
    }

    if (
      isVendor &&
      ['pending placer review'].includes(status.toLocaleLowerCase())
    )
      return false

    if (
      isCreditor &&
      ['pending agency review'].includes(status.toLocaleLowerCase())
    )
      return false

    return true
  }

  const mutationAcceptPostPlacement = useMutation({
    mutationFn: async () => {
      setIsAccepting(true)
      return postPlacementPostOperations.acceptPostPlacement(
        requestId,
        postPlacementData?.requestType
      )
    },
    onSuccess: async () => {
      setIsAccepting(false)
      refetchDetails()
      SuccessToast('Post-Placement accepted')
    },
    onError: async () => {
      setIsAccepting(false)
    },
  })

  const mutationSubmitPostPlacement = useMutation({
    mutationFn: async () => {
      setIsSubmiting(true)
      return postPlacementPostOperations.submitPostPlacement(
        requestId,
        postPlacementData?.requestType
      )
    },
    onSuccess: async () => {
      setIsSubmiting(false)
      refetchDetails()
      SuccessToast('Post-Placement submitted')
    },
    onError: async () => {
      setIsSubmiting(false)
    },
  })

  const mutationRejectPostPlacement = useMutation({
    mutationFn: async () => {
      setIsRejecting(true)
      return postPlacementPostOperations.rejectPostPlacement(
        requestId,
        rejectForm?.rejectReason || '',
        postPlacementData?.requestType
      )
    },
    onSuccess: async () => {
      setIsRejecting(false)
      refetchDetails()
      SuccessToast('Post-Placement rejected')
      setRejectModalIsOpen(false)
    },
    onError: async () => {
      setIsRejecting(false)
    },
  })

  const mutationWithdrawPostPlacement = useMutation({
    mutationFn: async () => {
      setIsWithdrawing(true)
      return postPlacementPostOperations.withdrawPostPlacement(
        requestId,
        withdrawForm?.rejectReason || '',
        postPlacementData?.requestType
      )
    },
    onSuccess: async () => {
      setIsRejecting(false)
      refetchDetails()
      SuccessToast('Post-Placement withdrawn')
      setOpenWithdrawDialog(false)
    },
    onError: async () => {
      setIsRejecting(false)
    },
  })

  const acceptClickHandler = () => {
    mutationAcceptPostPlacement.mutate()
  }

  const submitClickHandler = () => {
    mutationSubmitPostPlacement.mutate()
  }

  const rejectClickHandler = () => {
    setRejectModalIsOpen(true)
  }

  const rejectContinueClickHandler = () => {
    mutationRejectPostPlacement.mutate()
  }

  const withdrawContinueClickHandler = () => {
    mutationWithdrawPostPlacement.mutate()
  }

  const handleRejectReasonUpdate = (reason: any) => {
    setRejectForm((prevObj: any) => {
      return { ...prevObj, rejectReason: reason.currentTarget.value }
    })
  }

  const handleWithdrawReasonUpdate = (reason: any) => {
    setWithdrawForm((prevObj: any) => {
      return { ...prevObj, rejectReason: reason.currentTarget.value }
    })
  }

  const getConsumerName = () => {
    const lastName = postPlacementData?.lastName || ''
    const firstInitial = postPlacementData?.firstName
      ? postPlacementData?.firstName?.charAt(0)
      : ''

    if (!lastName && !firstInitial) {
      return 'N/A'
    }

    return lastName && firstInitial
      ? `${lastName}, ${firstInitial}`
      : lastName || firstInitial
  }

  const summaryData = {
    summaryDetails: [
      {
        blockValues: [
          {
            header: 'Loan ID',
            order: 0,
            value: postPlacementData?.lenderLoanId,
          },
          {
            header: 'Origination Date',
            order: 0,
            value: postPlacementData?.originationDate
              ? fFormatDate(postPlacementData?.originationDate)
              : undefined,
          },
          {
            header: 'Charge Off Balance',
            order: 0,
            value: fNumberCurrency(
              postPlacementData?.chargeOffBalance
                ? postPlacementData?.chargeOffBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
        ],
        gridItemValues: [
          {
            header: 'Consumer Name',
            order: 1,
            value: getConsumerName(),
          },
          {
            header: 'Lender Name',
            order: 2,
            value: postPlacementData?.lender,
          },
          {
            header: 'Portfolio Number',
            order: 3,
            value: 'N/A',
          },
          {
            header: 'Strategy ID',
            order: 4,
            value: postPlacementData?.strategyId,
          },
          {
            header: 'Placer Name',
            order: 5,
            value: postPlacementData?.creditor,
          },
          {
            header: 'Agency Name',
            order: 6,
            value: postPlacementData?.agency,
          },
          {
            header: 'Fund Amount',
            order: 7,
            value: fNumberCurrency(
              postPlacementData?.fundAmount
                ? postPlacementData?.fundAmount.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'First Delinquency Date',
            order: 8,
            value: postPlacementData?.firstDelinquencyDate
              ? fFormatDate(postPlacementData?.firstDelinquencyDate)
              : undefined,
          },
          {
            header: 'Write Off Date',
            order: 9,
            value: postPlacementData?.writeOffDate
              ? fFormatDate(postPlacementData?.writeOffDate)
              : undefined,
          },
          {
            header: 'Balance At Start Of Stage',
            order: 10,
            value: fNumberCurrency(
              postPlacementData?.originalTotalBalance
                ? postPlacementData?.originalTotalBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Total Payments',
            order: 11,
            value: fNumberCurrency(
              postPlacementData?.totalPayments
                ? postPlacementData?.totalPayments.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Last Payment Date',
            order: 12,
            value: postPlacementData?.lastPaymentDate
              ? fFormatDate(postPlacementData?.lastPaymentDate)
              : undefined,
          },
          {
            header: 'Last Payment Amount',
            order: 13,
            value: fNumberCurrency(
              postPlacementData?.lastPaymentAmount
                ? postPlacementData?.lastPaymentAmount.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Current Face Value',
            order: 14,
            value: fNumberCurrency(
              postPlacementData?.principalBalance
                ? postPlacementData?.principalBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Current Principal Balance',
            order: 15,
            value: fNumberCurrency(
              postPlacementData?.principalBalance
                ? postPlacementData?.principalBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Current Interest Balance',
            order: 16,
            value: fNumberCurrency(
              postPlacementData?.interestBalance
                ? postPlacementData?.interestBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
          {
            header: 'Current Fee Balance',
            order: 17,
            value: fNumberCurrency(
              postPlacementData?.currentFeeBalance
                ? postPlacementData?.currentFeeBalance.toFixed(2)
                : '',
              profileCountry()
            ),
          },
        ],
      },
    ],
  }

  const hastWithdrawReason = () => {
    return withdrawForm?.rejectReason && withdrawForm.rejectReason.length > 0
  }

  const hastRejectReason = () => {
    return rejectForm?.rejectReason && rejectForm.rejectReason.length > 0
  }

  return (
    <Page
      title={createCardTitle()}
      customBackUrl={getStandardUri(backUrl)}
      actions={
        <>
          <Box style={{ paddingLeft: '3px' }}>
            <Button
              color="primary"
              variant="secondary"
              style={{
                display: AllowSubmitRequested() ? undefined : 'none',
              }}
              onClick={submitClickHandler}
              leftIcon={<Icon fontSize="small" name="ArrowForward" />}
              isLoading={isSubmiting}
            >
              Submit Requested Data
            </Button>
          </Box>
          <Box style={{ paddingLeft: '3px' }}>
            <Button
              variant="secondary"
              style={{
                display:
                  !isLoadingPostPlacement &&
                  !isRefetchingPostPlacement &&
                  AllowWithdraw()
                    ? undefined
                    : 'none',
              }}
              onClick={() => setOpenWithdrawDialog(true)}
              leftIcon={<Icon fontSize="small" name="Delete" />}
            >
              Withdraw
            </Button>
          </Box>
          <Box style={{ paddingLeft: '3px' }}>
            <Button
              onClick={acceptClickHandler}
              variant="secondary"
              leftIcon={<Icon fontSize="small" name="CheckCircleOutline" />}
              style={{
                display:
                  !isLoadingPostPlacement &&
                  !isRefetchingPostPlacement &&
                  AllowAccept()
                    ? undefined
                    : 'none',
              }}
              isLoading={isAccepting}
            >
              Accept
            </Button>
          </Box>
          <Box style={{ paddingLeft: '3px' }}>
            <Button
              onClick={rejectClickHandler}
              variant="secondary"
              style={{
                display:
                  !isLoadingPostPlacement &&
                  !isRefetchingPostPlacement &&
                  AllowConfirmReject()
                    ? undefined
                    : 'none',
              }}
              leftIcon={<Icon fontSize="small" name="HighlightOff" />}
            >
              Reject
            </Button>
          </Box>
        </>
      }
    >
      <Box data-test-id="post-placement-details">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Display data-test-id="post-placement-details-summary">
              <Card
                variant="secondary"
                content={
                  <Content padding={3}>
                    <Summary
                      summaryData={summaryData}
                      displayFilters={true}
                      isLoading={
                        isLoadingPostPlacement || isRefetchingPostPlacement
                      }
                    ></Summary>
                  </Content>
                }
              ></Card>
            </Display>
          </Grid>
          <Grid item xs={6} sm={6}>
            <PostPlacementSpecificData
              data={postPlacementData?.specificDataJson}
              isLoading={isLoadingPostPlacement || isRefetchingPostPlacement}
              postPlacementType={postPlacementData?.requestType}
            ></PostPlacementSpecificData>
          </Grid>
          <Grid item xs={6} sm={6}>
            <PostPlacementDetails
              initiatedByAgency={postPlacementData?.initiatedByAgency}
              isLoading={isLoadingPostPlacement || isRefetchingPostPlacement}
              requestedBy={postPlacementData?.createdByName}
              requestedOn={postPlacementData?.createdAt || new Date()}
              rejectedBy={postPlacementData?.rejectedByName}
              rejectedComment={postPlacementData?.rejectComment}
              rejectedOn={postPlacementData?.rejectedAt}
            ></PostPlacementDetails>
          </Grid>
          <Grid item xs={6} sm={6} style={{ marginTop: -14 }}>
            <PostPlacementComment
              requestId={requestId}
              postPlacementOperations={postPlacementOperations}
              postPlacementPostOperations={postPlacementPostOperations}
            ></PostPlacementComment>
          </Grid>
        </Grid>
      </Box>
      <ModalDialog
        onClose={() => setRejectModalIsOpen(false)}
        isOpen={rejectModalIsOpen}
        header="Reject reason"
        onContinue={rejectContinueClickHandler}
        disableOkButton={!hastRejectReason()}
      >
        <Skeleton height="50px" isLoading={isRejecting} width="100%">
          <Content>
            <Input
              placeholder="Please, provide a reason for rejection:"
              variant="secondary"
              width="100%"
              onChange={handleRejectReasonUpdate}
              value={rejectForm?.rejectReason}
            />
          </Content>
        </Skeleton>
      </ModalDialog>
      <ModalDialog
        onClose={() => setOpenWithdrawDialog(false)}
        isOpen={withdrawModalIsOpen}
        header="Withdraw reason"
        onContinue={withdrawContinueClickHandler}
        disableOkButton={!hastWithdrawReason()}
      >
        <Skeleton height="50px" isLoading={isWithdrawing} width="100%">
          <Content>
            <Input
              placeholder="Please, provide a reason for withdraw:"
              variant="secondary"
              width="100%"
              onChange={handleWithdrawReasonUpdate}
              value={withdrawForm?.rejectReason}
            />
          </Content>
        </Skeleton>
      </ModalDialog>
    </Page>
  )
}

export default PostPlacementDetail
