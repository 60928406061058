import {
  Colors,
  CurrencyInput,
  DatePicker,
  Divider,
  Dropdown,
  fCalcPercentToNumber,
  Flex,
  fNumberCurrency,
  Grid,
  Group,
  Icon,
  Input,
  PercentInput,
  Tooltip,
  Typography,
} from 'everchain-uilibrary'
import React from 'react'
import { FilterAccountsResponse } from 'src/domain/models/accounts'
import { PortfolioTemplateInfo } from 'src/domain/models/portfolio/portfolio'
import { SplitBreakupParameter, SplitBreakupType } from 'src/utils/constants'
import { profileCountry } from 'src/utils/user'
import { SplitOption } from './SellAccountsModal'

interface SellAccountsSplitStepProps {
  templates?: PortfolioTemplateInfo[]
  cutOffDate: Date | null
  setCutOffDate: (value: Date | null) => void
  filterAccountsData: FilterAccountsResponse | undefined
  splitOptions: SplitOption[]
  setSplitOptions: (value: any) => void
  breakupParam: string
  setBreakupParam: (value: string) => void
  breakupType: string
  setBreakupType: (value: string) => void
  numberOfSplits: number
  setNumberOfSplits: (value: number) => void
}

const SellAccountsSplitStep: React.FC<SellAccountsSplitStepProps> = ({
  templates,
  cutOffDate,
  setCutOffDate,
  filterAccountsData,
  splitOptions,
  setSplitOptions,
  breakupParam,
  setBreakupParam,
  breakupType,
  setBreakupType,
  numberOfSplits,
  setNumberOfSplits,
}: SellAccountsSplitStepProps) => {
  const isFaceValueSplit = breakupParam === 'Value'
  const isPercentageSplit = isFaceValueSplit && breakupType === 'Percentage'

  const updateSplitOptionsAtIndex = (
    index: number,
    updates: Partial<SplitOption>
  ) => {
    const updatedSplitOptions = [...splitOptions]
    const selectedSplitOptions = updatedSplitOptions[index]

    for (const key in updates) {
      if (key) {
        const castedKey = key as keyof SplitOption
        selectedSplitOptions[castedKey] = updates[castedKey] as any
      }
    }

    setSplitOptions(updatedSplitOptions)
  }

  const getDifference = () => {
    if (isPercentageSplit) {
      const setValues = splitOptions.reduce(
        (sum, x) => sum + (x.percentage || 0),
        0
      )

      const percentageValue = fCalcPercentToNumber(setValues)
      return `${100 - (percentageValue ?? 0)}%`
    }

    const setValues = splitOptions.reduce(
      (sum, x) => sum + (x.totalAmount || 0),
      0
    )
    const difference = (filterAccountsData?.totalFaceValue ?? 0) - setValues
    return fNumberCurrency(difference, profileCountry())
  }

  const differenceTooltipTitle = isPercentageSplit
    ? 'This difference indicates the remaining percentage to reach 100%. A negative value means the total exceeds 100%.'
    : 'This difference shows the remaining amount required to match the total face value. A negative value means the total exceeds the total face value.'

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', flexDirection: 'row', padding: 0 }}
      >
        <Grid item xs={6}>
          <Dropdown
            id="account-breakup-parameter"
            placeholder="Account Breakup Parameter"
            value={breakupParam}
            width="100%"
            onChange={(option) => setBreakupParam(option.value)}
            options={SplitBreakupParameter}
            allowEmptyValue={false}
          />
        </Grid>
        {isFaceValueSplit && (
          <Grid item xs={6}>
            <Dropdown
              id="account-breakup-type"
              placeholder="Account Breakup Type"
              value={breakupType}
              width="100%"
              onChange={(option) => setBreakupType(option.value)}
              options={SplitBreakupType}
              allowEmptyValue={false}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
        }}
      >
        <Grid item xs={3}>
          <Input
            value={numberOfSplits}
            type="number"
            width="100%"
            placeholder="Number of Splits"
            variant="secondary"
            onChange={(e) => {
              let num = Number(e.target.value)
              if (num > 10) num = 10
              if (num < 2) num = 2
              while (splitOptions.length < num) {
                splitOptions.push({
                  templateId: templates?.[0].templateId
                    ? Number(templates?.[0].templateId)
                    : undefined,
                })
              }
              while (splitOptions.length > num) {
                splitOptions.pop()
              }
              setNumberOfSplits(num)
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: '1px' }}>
          <DatePicker
            label="Cut-Off Date"
            value={cutOffDate}
            onChange={setCutOffDate}
            width="100%"
          />
        </Grid>
        <Grid
          item
          xs={5}
          style={{
            display: 'flex',
            alignItems: 'end',
            marginBottom: '2px',
            justifyContent: 'center',
          }}
        >
          <Typography>
            Quantity of Accounts: {filterAccountsData?.totalAccounts}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
        }}
      >
        <Grid item xs={8}>
          <Group>
            <Typography>Total Face Value: </Typography>
            <Typography>
              {fNumberCurrency(
                filterAccountsData?.totalFaceValue,
                profileCountry()
              )}
            </Typography>
          </Group>
        </Grid>
        {isFaceValueSplit && (
          <Grid item xs={4}>
            <Group>
              <Typography>Difference: </Typography>
              <Typography>{getDifference()}</Typography>
              <Tooltip title={differenceTooltipTitle}>
                <Icon
                  name="InfoOutlined"
                  fontSize="14px"
                  color={Colors.primary}
                />
              </Tooltip>
            </Group>
          </Grid>
        )}
      </Grid>
      {[...Array(numberOfSplits)].map((x: number, i: number) => (
        <Grid item xs={12} key={`split-item-${i}`}>
          <Divider orientation="horizontal" />
          <Flex alignItems="center" marginTop={2}>
            <Grid item xs={1}>
              <Typography marginbottom="0">{`Set #${i + 1}`}</Typography>
            </Grid>
            <Grid
              item
              xs={isFaceValueSplit ? 8 : 10}
              style={{ marginBottom: '3px' }}
            >
              <Dropdown
                id={`template-id-${i}`}
                placeholder="Template"
                width="100%"
                onChange={(op: any) =>
                  updateSplitOptionsAtIndex(i, {
                    templateId: op.templateId,
                  })
                }
                value={splitOptions[i]?.templateId}
                options={templates ?? []}
                allowEmptyValue={false}
                disabled={!templates}
                valueOptionName="templateId"
                labelOptionName="templateName"
              />
            </Grid>
            {isFaceValueSplit && (
              <Grid item xs={3}>
                {isPercentageSplit ? (
                  <PercentInput
                    placeholder="Percentage"
                    width="100%"
                    value={splitOptions[i]?.percentage}
                    onChange={(e, value) => {
                      updateSplitOptionsAtIndex(i, {
                        percentage: Number(value),
                      })
                    }}
                  />
                ) : (
                  <CurrencyInput
                    placeholder="Face Value"
                    width="100%"
                    value={splitOptions[i]?.totalAmount}
                    onChange={(e, value) => {
                      updateSplitOptionsAtIndex(i, {
                        totalAmount: Number(value),
                      })
                    }}
                  />
                )}
              </Grid>
            )}
          </Flex>
        </Grid>
      ))}
    </Grid>
  )
}

export default SellAccountsSplitStep
