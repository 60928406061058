import React from 'react'
import BusinessList from './BusinessList/index'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IBusinessPostOperations } from 'src/domain/features/post/business/business'
import makeBusinessSettingsValidation from 'src/main/factories/validation/businessSettings'
import Page from '../Common/Page'

interface BusinessSettingsParams {
  businessGetOperations?: IBusinessGetOperations
  businessPostOperations?: IBusinessPostOperations
}

const BusinessSettings: React.FC<BusinessSettingsParams> = ({
  businessGetOperations,
  businessPostOperations,
}: BusinessSettingsParams) => {
  return (
    <Page title="Business Manager">
      <BusinessList
        businessGetOperations={businessGetOperations}
        businessPostOperations={businessPostOperations}
        validation={makeBusinessSettingsValidation}
      />
    </Page>
  )
}

export default BusinessSettings
