import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IComplaintPostOperations } from 'src/domain/features/post/complaint/complaint'
import { ApiError } from 'src/domain/models/api/api'
import * as types from 'src/domain/models/complaint'
import * as urls from './urls'

export class ComplaintPostData implements IComplaintPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async saveComplaint(
    request: types.SaveComplaintRequest
  ): Promise<number | ApiError> {
    return this.httpClient.post(urls.ComplaintsSaveComplaint, request)
  }

  async updateComplaint(
    request: types.UpdateComplaintRequest
  ): Promise<number | ApiError> {
    return this.httpClient.post(urls.ComplaintsUpdateComplaint, request)
  }

  async updateComplaintStatus(
    request: types.UpdateComplaintStatusRequest
  ): Promise<number | ApiError> {
    return this.httpClient.post(urls.ComplaintsUpdateComplaintStatus, request)
  }

  async saveComplaintComment(
    request: types.SaveComplaintCommentRequest
  ): Promise<number | ApiError> {
    return this.httpClient.post(urls.ComplaintsSaveComplaintComment, request)
  }

  async updateComplaintComment(
    request: types.UpdateComplaintCommentRequest
  ): Promise<number | ApiError> {
    return this.httpClient.post(urls.ComplaintsUpdateComplaintComment, request)
  }

  async saveComplaintInfoRequest(
    request: types.SaveComplaintInfoRequest
  ): Promise<number | ApiError> {
    return this.httpClient.post(
      urls.ComplaintsSaveComplaintInfoRequest,
      request
    )
  }

  async updateComplaintInfoRequest(
    request: types.UpdateComplaintInfoRequest
  ): Promise<number | ApiError> {
    return this.httpClient.post(
      urls.ComplaintsUpdateComplaintInfoRequest,
      request
    )
  }

  async saveComplaintAttachment(
    complaintId: number,
    infoRequestId: number,
    file: any,
    description: string
  ): Promise<number | ApiError> {
    const form = new FormData()
    form.append('complaintId', complaintId.toString())
    form.append('infoRequestId', infoRequestId.toString())
    form.append('file', file)
    form.append('description', description)

    return this.httpClient.post(urls.ComplaintsSaveAttachment, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async updateComplaintAttachment(
    request: types.UpdateComplaintAttachmentRequest
  ): Promise<number | ApiError> {
    return this.httpClient.post(urls.ComplaintsUpdateAttachment, request)
  }

  async saveComplaintAssignedUser(
    request: types.SaveComplaintAssignedUserRequest
  ): Promise<number | ApiError> {
    return this.httpClient.post(urls.ComplaintsSaveAssignedUser, request)
  }
}
