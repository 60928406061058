import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import {
  MediaUploadPostSaveAccountMediaFile,
  MediaUploadPostSaveMediaFile,
} from './urls'
import { IMediaUploadPostOperations } from 'src/domain/features/post/mediaUpload/mediaUpload'
import {
  MediaUploadSaveAccountMediaFileRequest,
  MediaUploadSaveMediaFileRequest,
} from 'src/domain/models/mediaUpload'

export class MediaUploadPostData implements IMediaUploadPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}
  async saveMediaFile(
    request: MediaUploadSaveMediaFileRequest
  ): Promise<string> {
    const response = await this.httpClient.post(
      MediaUploadPostSaveMediaFile,
      request
    )
    return response?.data
  }
  async saveAccountMediaFile(
    request: MediaUploadSaveAccountMediaFileRequest
  ): Promise<string> {
    const response = await this.httpClient.post(
      MediaUploadPostSaveAccountMediaFile,
      request
    )
    return response?.data
  }
}
