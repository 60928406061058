import {
  Colors,
  fNumberCurrency,
  fNumberToPercentage,
  Grid,
  Skeleton,
  Typography,
} from 'everchain-uilibrary'
import React from 'react'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import {
  FilterAccountsResponse,
  SplitAccountsResponse,
} from 'src/domain/models/accounts'
import { useCustomQuery } from 'src/infra/reactQuery'
import { profileCountry } from 'src/utils/user'
import { SplitOption } from './SellAccountsModal'
import { PortfolioTemplateInfo } from 'src/domain/models/portfolio/portfolio'
import { SplitBreakupParameter, SplitBreakupType } from 'src/utils/constants'

interface SellAccountsSimulateSplitStepProps {
  accountPostOperations: IAccountPostOperations
  splitOptions: SplitOption[]
  templates?: PortfolioTemplateInfo[]
  breakupType: string
  breakupParam: string
  filterAccountsResponse: FilterAccountsResponse | undefined
  splitAccountsData: SplitAccountsResponse[] | undefined
  setSplitAccountsData: (value: any) => void
}

const SellAccountsSimulateSplitStep: React.FC<
  SellAccountsSimulateSplitStepProps
> = ({
  accountPostOperations,
  splitOptions,
  templates,
  breakupType,
  breakupParam,
  filterAccountsResponse,
  splitAccountsData,
  setSplitAccountsData,
}: SellAccountsSimulateSplitStepProps) => {
  const isFaceValueSplit = breakupParam === 'Value'
  const isPercentageSplit = isFaceValueSplit && breakupType === 'Percentage'

  const { isFetching: loadingSplitAccountsData } = useCustomQuery<
    SplitAccountsResponse[]
  >(
    ['splitAccounts'],
    async () =>
      accountPostOperations
        ?.splitAccounts({
          breakupParameter: breakupParam as any,
          breakupType: breakupType as any,
          items: filterAccountsResponse?.accounts ?? [],
          params: splitOptions ?? [],
        })
        .then((result) => {
          setSplitAccountsData(result)
          return result
        }),
    { cacheTime: 0, enabled: true }
  )

  const hasExceededTolerance = (tolerance = 0.05) => {
    if (!isFaceValueSplit) {
      return false
    }

    const sumAmount = splitOptions.reduce(
      (acc, x) => acc + (x.totalAmount ?? 0),
      0
    )

    if (!isPercentageSplit && sumAmount <= 0) {
      return false
    }

    return splitAccountsData?.some((data, index) => {
      const target = splitOptions[index]
      const targetPercentage = isPercentageSplit
        ? target.percentage ?? 0
        : (target.totalAmount ?? 0) / sumAmount

      const lowerBound = targetPercentage * (1 - tolerance)
      const upperBound = targetPercentage * (1 + tolerance)

      return (
        (data?.percentage ?? 0) < lowerBound ||
        (data.percentage ?? 0) > upperBound
      )
    })
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ paddingBottom: 7 }}>
        <Typography color={Colors.black}>
          Submitting this request will generate the split files. Note that the
          system operates with a 5% tolerance for the split calculation because
          accounts have varying values, making it challenging to achieve an
          exact split. Use the 'Go Back' button and adjust the split values if
          needed.
        </Typography>
      </Grid>
      {hasExceededTolerance() && (
        <Grid item xs={12} style={{ paddingBottom: 7 }}>
          <Typography color={Colors.error}>
            Some splits do not meet the 5% target tolerance. This may be due to
            small split values or a limited number of accounts, making an exact
            split challenging. You can 'Go Back' and review the split parameters
            to adjust as needed.
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Grid item xs={4}>
          <Typography>Account Breakup Parameter:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            {SplitBreakupParameter.find((x) => x.value === breakupParam)?.label}
          </Typography>
        </Grid>
      </Grid>
      {isFaceValueSplit && (
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Grid item xs={4}>
            <Typography>Account Breakup Type:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {SplitBreakupType.find((x) => x.value === breakupType)?.label}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Skeleton isLoading={loadingSplitAccountsData} width="100%" height={150}>
        {splitAccountsData?.map((split, idx) => (
          <>
            <Grid
              item
              style={{
                display: 'flex',
                marginTop: '4px',
                paddingTop: '6px',
                borderTop: `1px solid ${Colors.gray}`,
              }}
              xs={12}
            >
              <Grid item xs={2}>
                <Typography>Template:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  {
                    templates?.find(
                      (item) =>
                        item.templateId.toString() ===
                        split.templateId.toString()
                    )?.templateName
                  }
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Number Of Accounts:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{split.items.length}</Typography>
              </Grid>
            </Grid>
            <Grid item style={{ display: 'flex', paddingTop: '2px' }} xs={12}>
              <Grid item xs={2}>
                <Typography>Total Amount:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  {fNumberCurrency(split.totalAmount, profileCountry())}
                  {!isPercentageSplit &&
                    ` (Target: ${fNumberCurrency(
                      splitOptions[idx].totalAmount
                    )})`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Percentage:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {fNumberToPercentage(split.percentage, '', 2)}
                  {isPercentageSplit &&
                    ` (Target: ${fNumberToPercentage(
                      splitOptions[idx].percentage
                    )})`}
                </Typography>
              </Grid>
            </Grid>
          </>
        ))}
      </Skeleton>
    </Grid>
  )
}

export default SellAccountsSimulateSplitStep
