import React, { useEffect, useState } from 'react'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import SellAccountsFilterStep from './SellAccountsFilterStep'
import {
  Box,
  Button,
  Flex,
  Group,
  Icon,
  ModalDialog,
  SuccessToast,
  WarningToast,
} from 'everchain-uilibrary'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { AssetType } from 'src/domain/models/assetTypes/assetTypes'
import {
  FilterAccountsResponse,
  ProcessSellAccountsItemsDto,
  SplitAccountsResponse,
} from 'src/domain/models/accounts'
import { FormFilter } from '..'
import SellAccountsSplitStep from './SellAccountsSplitStep'
import { PortfolioTemplateInfo } from 'src/domain/models/portfolio/portfolio'
import { SplitBreakupParameter, SplitBreakupType } from 'src/utils/constants'
import SellAccountsSimulateSplitStep from './SellAccountsSimulateSplitStep'
import SellAccountsNonSplitSubmitStep from './SellAccountsNonSplitSubmitStep'
import { useMutation } from '@tanstack/react-query'

export interface SplitOption {
  templateId?: number
  percentage?: number
  totalAmount?: number
}

export type TypeUpload = 'allAccounts' | 'filteredAccounts' | 'file'

interface SellAccountsModalProps {
  businessOperations?: IBusinessGetOperations
  accountPostOperations: IAccountPostOperations
  open: boolean
  setIsOpen: (open: boolean) => void
  assetTypes: AssetType[]
  filterAccounts: FormFilter | undefined
  loadingData: boolean
  templates?: PortfolioTemplateInfo[]
  cleanItems: () => void
  refetchAccounts: (options?: any) => Promise<any>
  typeUpload: TypeUpload
  selectedECAIDs?: string[]
}

const SellAccountsModal: React.FC<SellAccountsModalProps> = ({
  businessOperations,
  accountPostOperations,
  open,
  setIsOpen,
  assetTypes,
  filterAccounts,
  loadingData,
  templates,
  cleanItems,
  refetchAccounts,
  typeUpload,
  selectedECAIDs,
}: SellAccountsModalProps) => {
  const [step, setStep] = useState<number>(1)
  const [isLoadingSell, setIsLoadingSell] = useState<boolean>(false)
  const [placerId, setPlacerId] = useState<string | undefined>()
  const [assetTypeId, setAssetTypeId] = useState<number | undefined>(undefined)
  const [templateId, setTemplateId] = useState<number | undefined>(undefined)
  const [isMultiPortfolio, setIsMultiPortfolio] = useState<boolean>(false)
  const [cutOffDate, setCutOffDate] = useState<Date | null>(new Date())
  const [splitOptions, setSplitOptions] = useState<SplitOption[]>([{}, {}])
  const [numberOfSplits, setNumberOfSplits] = useState<number>(2)
  const [filterAccountsData, setFilterAccountsData] = useState<
    FilterAccountsResponse | undefined
  >()
  const [splitAccountsData, setSplitAccountsData] = useState<
    SplitAccountsResponse[] | undefined
  >(undefined)
  const [breakupParam, setBreakupParam] = useState<string>(
    SplitBreakupParameter[0].value
  )
  const [breakupType, setBreakupType] = useState<string>(
    SplitBreakupType[0].value
  )

  const lastStep = isMultiPortfolio ? 3 : 4
  const isFirstStep = step === 1
  const isSplitStep = step === 2
  const isSimulateSplitStep = step === 3
  const isSubmitStep = step === 4
  const isLastStep = step === lastStep

  const disableNextButton = (): boolean => {
    if (isFirstStep) {
      return !filterAccountsData?.totalAccounts
    }

    if (isSplitStep) {
      const isQuantity = breakupParam === 'Quantity'
      const isPercentageValid =
        breakupType === 'Percentage' &&
        splitOptions.every((option) => (option?.percentage ?? 0) > 0) &&
        splitOptions.reduce(
          (sum, option) => sum + (option?.percentage ?? 0),
          0
        ) === 1

      const isFaceValueValid =
        breakupType === 'FaceValue' &&
        splitOptions.every((option) => (option?.totalAmount ?? 0) > 0) &&
        splitOptions.reduce(
          (sum, option) => sum + (option?.totalAmount ?? 0),
          0
        ) === (filterAccountsData?.totalFaceValue ?? 0)

      const isTemplateFilled = splitOptions.every(
        (option) => option?.templateId
      )

      return !!!(
        (isQuantity || isPercentageValid || isFaceValueValid) &&
        isTemplateFilled &&
        cutOffDate
      )
    }

    if (isLastStep) {
      const hasMultiPortfolioAccounts =
        !isMultiPortfolio || splitAccountsData?.length
      const hasSinglePortfolioAccounts =
        isMultiPortfolio || filterAccountsData?.accounts.length
      const hasPortfolioTemplate = isMultiPortfolio || templateId !== undefined

      return !!!(
        hasMultiPortfolioAccounts &&
        hasSinglePortfolioAccounts &&
        hasPortfolioTemplate &&
        cutOffDate
      )
    }

    return false
  }

  const currentTemplates = templates?.filter(
    (x) => x.assetId?.toString() === assetTypeId?.toString()
  )

  const mutationSellAccounts = useMutation({
    mutationFn: async (params: any) => {
      setIsLoadingSell(true)
      const assetTypeName =
        assetTypes.find(
          (asset) => asset.id.toString() === assetTypeId?.toString()
        )?.name ?? ''

      const requestItems: ProcessSellAccountsItemsDto[] | undefined =
        isMultiPortfolio
          ? splitAccountsData?.map((split) => ({
              ecaids: split.items.map((x) => x.ecaid) ?? [],
              portfolioTypeId:
                templates?.find(
                  (template) =>
                    template.templateId.toString() ===
                    split.templateId.toString()
                )?.portfolioType ?? 0,
              templateId: split.templateId,
              assetTypeName: assetTypeName,
            }))
          : [
              {
                ecaids: filterAccountsData?.accounts.map((x) => x.ecaid) ?? [],
                portfolioTypeId:
                  templates?.find(
                    (template) =>
                      template.templateId.toString() === templateId?.toString()
                  )?.portfolioType ?? 0,
                templateId: templateId!,
                assetTypeName: assetTypeName,
              },
            ]

      return accountPostOperations.processSellAccounts(
        placerId!,
        cutOffDate!,
        requestItems
      )
    },
    onSuccess: async (response: any) => {
      if (response?.data.fileValidatonResults?.length > 0) {
        WarningToast(response.data.fileValidatonResults[0].errorMessage)
      } else {
        if (response?.data.portfolioCreated) {
          SuccessToast(
            'Account sent to Marktplace successfully. Portfolio number ' +
              response?.data.portfolioCreated +
              ' generated'
          )
        } else {
          SuccessToast('Account sent to Marktplace successfully.')
        }
      }
      refetchAccounts()
      cleanItems()
      returnEmptyState()
      setIsOpen(false)
    },
    onError: () => {
      setIsLoadingSell(false)
    },
  })

  const handleContinue = () => {
    if (!isLastStep) {
      if (isMultiPortfolio) {
        setStep(step + 1)
        return
      }

      setStep(lastStep)
    } else {
      mutationSellAccounts.mutate(null)
    }
  }

  const handleGoBack = () => {
    if (isMultiPortfolio) {
      setStep(step - 1)
      return
    }

    setStep(1)
  }

  const handleCancel = () => {
    setAssetTypeId(
      filterAccounts?.assetTypeId
        ? Number(filterAccounts.assetTypeId)
        : undefined
    )

    returnEmptyState()
    setIsOpen(false)
  }

  const returnEmptyState = () => {
    setIsMultiPortfolio(false)
    setFilterAccountsData(undefined)
    setStep(1)
    setCutOffDate(new Date())
    setIsLoadingSell(false)
    setSplitOptions([{}, {}])
    setNumberOfSplits(2)
    setSplitAccountsData(undefined)
    setTemplateId(undefined)
  }

  const getContinueButtonText = () => {
    if (isFirstStep) {
      return 'Next'
    }

    return step === 2 ? 'Simulate' : 'Confirm'
  }

  useEffect(() => {
    if (filterAccounts?.assetTypeId) {
      setAssetTypeId(Number(filterAccounts.assetTypeId))
    }
  }, [filterAccounts])

  useEffect(() => {
    if (templates?.length) {
      setSplitOptions((prev) => {
        const newTemplateId = Number(templates[0].templateId)

        const needsUpdate = prev.some((x) => x.templateId !== newTemplateId)
        if (!needsUpdate) return prev

        return prev.map((x) => ({
          ...x,
          templateId: newTemplateId,
        }))
      })
    }
  }, [templates])

  return (
    <ModalDialog
      header="Sell Accounts"
      isOpen={open}
      width="100%"
      maxwidth="600px"
      showActionButtons={false}
      onClose={() => setIsOpen(false)}
    >
      {isFirstStep && (
        <SellAccountsFilterStep
          businessOperations={businessOperations}
          accountPostOperations={accountPostOperations}
          filterAccounts={filterAccounts}
          assetTypes={assetTypes}
          assetTypeId={assetTypeId}
          setAssetTypeId={setAssetTypeId}
          placerId={placerId}
          setPlacerId={setPlacerId}
          isMultiPortfolio={isMultiPortfolio}
          setIsMultiPortfolio={setIsMultiPortfolio}
          filterAccountsData={filterAccountsData}
          setFilterAccountsData={setFilterAccountsData}
          loadingData={loadingData}
          typeUpload={typeUpload}
          selectedECAIDs={selectedECAIDs}
        />
      )}
      {isSplitStep && (
        <SellAccountsSplitStep
          templates={currentTemplates}
          cutOffDate={cutOffDate}
          setCutOffDate={setCutOffDate}
          filterAccountsData={filterAccountsData}
          splitOptions={splitOptions}
          setSplitOptions={setSplitOptions}
          breakupParam={breakupParam}
          setBreakupParam={setBreakupParam}
          breakupType={breakupType}
          setBreakupType={setBreakupType}
          numberOfSplits={numberOfSplits}
          setNumberOfSplits={setNumberOfSplits}
        />
      )}
      {isSimulateSplitStep && (
        <SellAccountsSimulateSplitStep
          accountPostOperations={accountPostOperations}
          breakupParam={breakupParam}
          breakupType={breakupType}
          splitOptions={splitOptions}
          templates={currentTemplates}
          filterAccountsResponse={filterAccountsData}
          splitAccountsData={splitAccountsData}
          setSplitAccountsData={setSplitAccountsData}
        />
      )}
      {isSubmitStep && (
        <SellAccountsNonSplitSubmitStep
          cutOffDate={cutOffDate}
          setCutOffDate={setCutOffDate}
          templateId={templateId}
          setTemplateId={setTemplateId}
          templates={currentTemplates}
        />
      )}
      <Flex w="100%" pt={2} justifyContent="space-between">
        <Box>
          {step > 1 && (
            <Button
              variant="secondary"
              leftIcon={<Icon name="KeyboardBackspace" />}
              onClick={handleGoBack}
              style={{ marginBottom: '10px' }}
            >
              Go Back
            </Button>
          )}
        </Box>
        <Group>
          <Button
            variant="secondary"
            onClick={handleCancel}
            style={{ marginBottom: '10px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleContinue}
            style={{ marginBottom: '10px' }}
            disabled={disableNextButton()}
            isFetching={isLoadingSell}
          >
            {getContinueButtonText()}
          </Button>
        </Group>
      </Flex>
    </ModalDialog>
  )
}

export default SellAccountsModal
