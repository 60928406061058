/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useContext, useState } from 'react'
import {
  Content,
  Form,
  Header,
  maskPhoneNumber,
  SuccessToast,
  useForm,
} from 'everchain-uilibrary'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { IComplaintPostOperations } from 'src/domain/features/post/complaint/complaint'
import { useCustomQuery } from 'src/infra/reactQuery'
import { useHistory, useParams } from 'react-router-dom'
import ComplaintSummary from './Components/ComplaintSummary'
import ComplaintActions from './Components/ComplaintActions'
import ComplaintInfoAccordion from './Components/ComplaintInfoAccordion'
import { getStandardUri } from 'src/utils/common'
import { COMPLAINT } from 'src/presentation/routes'
import makeComplaintValidation from 'src/main/factories/validation/complaint'
import ComplaintCommentsAccordion from './Components/ComplaintCommentsAccordion'
import ComplaintResponseAccordion from './Components/ComplaintResponseAccordion'
import { Grid } from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'
import ComplaintInfoRequestAccordion from './Components/ComplaintInfoRequestAccordion'
import { IClientGetOperations } from 'src/domain/features/get/clients/clients'
import ComplaintAttachmentsAccordion from './Components/ComplaintAttachmentsAccordion'
import ComplaintComplianceAccordion from './Components/ComplaintComplianceAccordion'
import { INTERNAL } from 'src/utils/constants'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { AuditInfoAccordion } from 'src/presentation/components/Audit'
import { IAuditGetOperations } from 'src/domain/features/get/audit/audit'

interface RouteParams {
  ecaid?: string
  complaintId?: string
}

interface ComplaintDetailProps {
  complaintGetOperations?: IComplaintGetOperations
  complaintPostOperations?: IComplaintPostOperations
  clientGetOperations?: IClientGetOperations
  accountGetOperations?: IAccountGetOperations
  auditGetOperations?: IAuditGetOperations
}

const ComplaintDetail: React.FC<ComplaintDetailProps> = ({
  complaintGetOperations,
  complaintPostOperations,
  clientGetOperations,
  accountGetOperations,
  auditGetOperations,
}) => {
  const { ecaid, complaintId } = useParams<RouteParams>()
  const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false)
  const { isCreditor, userPermissions } = useContext(AuthContext)
  const methods = useForm()
  const history = useHistory()

  const isInternal = userPermissions.type.toLowerCase() === INTERNAL

  const { data: previewData, isFetching: loadingPreviewData } = useCustomQuery(
    ['getPreview', ecaid],
    async () =>
      complaintGetOperations?.getPreview(ecaid!).then((data) => {
        methods.setValue('summary', data.summary, { shouldDirty: true })
        return data
      }),
    { cacheTime: 0, enabled: !!ecaid && !complaintId }
  )

  const { data: accountStatusesData, isLoading: loadingAccountStatusesData } =
    useCustomQuery(
      ['getComplaintAccountStatuses'],
      async () => complaintGetOperations?.getComplaintAccountStatuses(),
      { cacheTime: 0, enabled: true }
    )

  const { data: complaintData, isFetching: loadingComplaintData } =
    useCustomQuery(
      ['getComplaintById', complaintId],
      async () =>
        complaintGetOperations
          ?.getComplaintById(Number(complaintId))
          .then((data) => {
            methods.setValue('vendorId', data.vendorId)
            methods.setValue('status', data.status)
            methods.setValue('summary', data.summary)
            methods.setValue('sourceType', data.sourceType)
            methods.setValue('attorneyContacted', data.attorneyContacted)
            methods.setValue('complainantName', data.complainantName)

            const complainantPhoneValue = data.complainantPhone
              ? maskPhoneNumber(data.complainantPhone)
              : undefined
            methods.setValue('complainantPhone', complainantPhoneValue)
            methods.setValue('complainantEmail', data.complainantEmail)

            methods.setValue('accountStatus', data.accountStatus)
            methods.setValue('employeesInvolved', data.employeesInvolved)
            methods.setValue('agencyAction', data.agencyAction)
            methods.setValue('agencyResponse', data.agencyResponse)
            methods.setValue(
              'agencyPreventionSteps',
              data.agencyPreventionSteps
            )
            methods.setValue('followUpRequired', data.followUpRequired)
            methods.setValue('documentsRequired', data.documentsRequired)

            methods.setValue('violationType', data.violationType)
            methods.setValue('outcome', data.outcome)
            methods.setValue('complaintResolution', data.complaintResolution)
            methods.setValue('debtTraderActions', data.debtTraderActions)
            methods.setValue('category', data.category)

            return data
          }),
      { cacheTime: 0, enabled: !!complaintId }
    )

  const isEditing = !!complaintId
  const complaint = isEditing ? complaintData : previewData
  const loadingComplaint = isEditing ? loadingComplaintData : loadingPreviewData

  const onSaveComplaint = (request: any) => {
    setLoadingSaveButton(true)

    if (isEditing) {
      complaintPostOperations
        ?.updateComplaint(request)
        .then(() => {
          SuccessToast('Complaint updated successfully')
          history.push(getStandardUri(COMPLAINT))
        })
        .finally(() => {
          setLoadingSaveButton(false)
        })
    } else {
      complaintPostOperations
        ?.saveComplaint(request)
        .then(() => {
          SuccessToast('Complaint created successfully')
          history.push(getStandardUri(COMPLAINT))
        })
        .finally(() => {
          setLoadingSaveButton(false)
        })
    }
  }

  const handleSaveButton = (formData: any) => {
    if (complaint) {
      const request: any = {
        summary: formData.summary,
        sourceType: formData.sourceType,
        attorneyContacted: formData.attorneyContacted,
        complainantName: formData.complainantName,
        complainantPhone: formData.complainantPhone,
        complainantEmail: formData.complainantEmail,
      }

      if (isEditing) {
        request.status = formData.status
        request.complaintId = complaint.id
        request.vendorId = formData.vendorId
        request.accountStatus = formData.accountStatus
        request.employeesInvolved = formData.employeesInvolved
        request.agencyAction = formData.agencyAction
        request.agencyResponse = formData.agencyResponse
        request.agencyPreventionSteps = formData.agencyPreventionSteps
        request.followUpRequired = formData.followUpRequired
        request.documentsRequired = formData.documentsRequired
        request.violationType = formData.violationType
        request.outcome = formData.outcome
        request.complaintResolution = formData.complaintResolution
        request.debtTraderActions = formData.debtTraderActions
        request.category = formData.category
      } else {
        request.status = complaint.status
        request.accountId = complaint.accountId
        request.ecaid = complaint.ecaid
        request.status = complaint.status
        request.placerId = complaint.placerId
        request.vendorId = complaint.vendorId
        request.followUpRequired = complaint.followUpRequired
        request.documentsRequired = complaint.documentsRequired
      }

      onSaveComplaint(request)
    }
  }

  return (
    <Content id="complaint-detail">
      <Header title="Complaint" subtitle="Complaint Detail" />
      <Form methods={methods} onSubmit={handleSaveButton}>
        <ComplaintActions
          complaintDto={complaint}
          isLoading={loadingComplaint}
          isEditing={isEditing}
          isLoadingSaveButton={loadingSaveButton}
          clientGetOperations={clientGetOperations}
          complaintPostOperations={complaintPostOperations}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ComplaintSummary
              complaintDto={complaint}
              isLoading={loadingComplaint}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <ComplaintInfoAccordion
                isLoading={loadingComplaint}
                complaintGetOperations={complaintGetOperations}
                complaintValidation={makeComplaintValidation}
                formMethods={methods}
              />
            </Grid>
            {isEditing && (
              <>
                <Grid item xs={12}>
                  <ComplaintCommentsAccordion
                    complaintGetOperations={complaintGetOperations}
                    complaintPostOperations={complaintPostOperations}
                    complaintId={complaint?.id}
                    isLoading={loadingComplaint}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ComplaintAttachmentsAccordion
                    complaintGetOperations={complaintGetOperations}
                    complaintPostOperations={complaintPostOperations}
                    complaintId={complaint?.id}
                    isLoading={loadingComplaint}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ComplaintInfoRequestAccordion
                    complaintGetOperations={complaintGetOperations}
                    complaintPostOperations={complaintPostOperations}
                    complaintId={complaint?.id}
                    isLoading={loadingComplaint}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            {isEditing && (
              <>
                {!isCreditor && (
                  <Grid item xs={12}>
                    <ComplaintResponseAccordion
                      accountStatusesData={accountStatusesData}
                      isLoading={loadingComplaint}
                      isLoadingAccountStatusesData={loadingAccountStatusesData}
                    />
                  </Grid>
                )}
                {isInternal && (
                  <>
                    <Grid item xs={12}>
                      <ComplaintComplianceAccordion
                        complaintGetOperations={complaintGetOperations}
                        accountGetOperations={accountGetOperations}
                        complaintDto={complaint}
                        accountStatusesData={accountStatusesData}
                        isLoading={loadingComplaint}
                        isLoadingAccountStatusesData={
                          loadingAccountStatusesData
                        }
                        methods={methods}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AuditInfoAccordion
                        groupKey={Number(complaintId)}
                        auditGroup="Complaint"
                        auditGetOperations={auditGetOperations}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Form>
    </Content>
  )
}

export default ComplaintDetail
